import ClasstitleStyle from "./classTitleStyle";
// import DownloadIcon from "./svg/arrow-download.svg";
const Icon = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.647 13.4663L13.98 14.8133L13.356 15.9483L12.569 15.9233L11.789 17.2733H10.22L11.413 15.2073L11.006 14.5873L11.648 13.4663H11.647ZM12.083 12.7033L14.982 7.64226C15.0656 7.49654 15.177 7.36872 15.31 7.26611C15.4431 7.1635 15.595 7.08812 15.7571 7.04428C15.9193 7.00045 16.0885 6.98901 16.2551 7.01063C16.4217 7.03224 16.5824 7.08649 16.728 7.17026C17.345 7.52526 17.563 8.30826 17.22 8.93026L14.405 14.0443L12.083 12.7043V12.7033ZM5.9 15.4773H8.67C8.90843 15.4773 9.13709 15.572 9.30569 15.7406C9.47428 15.9092 9.569 16.1378 9.569 16.3763C9.569 16.6147 9.47428 16.8434 9.30569 17.012C9.13709 17.1805 8.90843 17.2753 8.67 17.2753H5.899C5.66057 17.2753 5.43191 17.1805 5.26331 17.012C5.09472 16.8434 5 16.6147 5 16.3763C5 16.1378 5.09472 15.9092 5.26331 15.7406C5.43191 15.572 5.66057 15.4773 5.899 15.4773H5.9Z" fill="black"/>
<path d="M7.6 8.15H2.25V12.675C2.25 12.9734 2.13147 13.2595 1.9205 13.4705C1.70952 13.6815 1.42337 13.8 1.125 13.8C0.826631 13.8 0.540483 13.6815 0.329505 13.4705C0.118526 13.2595 0 12.9734 0 12.675L0 1.125C0 0.826631 0.118526 0.540483 0.329505 0.329505C0.540483 0.118526 0.826631 0 1.125 0C1.42337 0 1.70952 0.118526 1.9205 0.329505C2.13147 0.540483 2.25 0.826631 2.25 1.125V5.9H7.6V1.125C7.6 0.826631 7.71853 0.540483 7.9295 0.329505C8.14048 0.118526 8.42663 0 8.725 0C9.02337 0 9.30952 0.118526 9.5205 0.329505C9.73147 0.540483 9.85 0.826631 9.85 1.125V12.675C9.85 12.9734 9.73147 13.2595 9.5205 13.4705C9.30952 13.6815 9.02337 13.8 8.725 13.8C8.42663 13.8 8.14048 13.6815 7.9295 13.4705C7.71853 13.2595 7.6 12.9734 7.6 12.675V8.15Z" fill="black"/>
</svg>`;
const LOADER_TIMEOUT = 500;

/**
 * @class titleStyleToolCustom
 * @classdesc titleStyleToolCustom for Editor.js 2.0
 *
 * @property {API} api - Editor.js API
 * @property {titleStyle} data
 * @property {titleStyleToolCustomConfig} config
 */
export default class titleStyleToolCustom {
  /**
   * @param {titleStyle} data
   * @param {object} config
   * @param {API} api
   */
  // eslint-disable-next-line no-unused-vars
  constructor({ data, api, config, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this.nodes = {
      wrapper: null,
      button: null,
      title: null,
    };

    this._data = data;

    this.blockIndex = this.api.blocks.getCurrentBlockIndex() + 1;
    this.settings = [
      {
        name: "styleHeader",
      },
    ];
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   */
  static get toolbox() {
    return {
      icon: Icon,
      title: "タイトルスタイル",
    };
  }

  /**
   * Tool's CSS classes
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      defaultClass: "titleStyle",
      changeClass: this.data.class || "titleStyle1",
      boxDefault: "titleStyle1",
      loader: this.api.styles.loader,
      /**
       * Tool's classes
       */
      wrapper: "cdx-titleStyle",
      wrapperWithFile: "cdx-titleStyle--with-file",
      wrapperLoading: "cdx-titleStyle--loading",
      box: "cdx-titleStyle__button",
      title: "cdx-titleStyle__title",
      size: "cdx-titleStyle__size",
      downloadButton: "cdx-titleStyle__download-button",
      fileInfo: "cdx-titleStyle__file-info",
      fileIcon: "cdx-titleStyle__file-icon",
      settingsButtonActive: "cdx-settings-button--active",
      settingsButton: "cdx-settings-button",
    };
  }

  /**
   * Validate block data:
   * - check for emptiness
   *
   * @param {titleStyle} savedData — data received after saving
   * @returns {boolean} false if saved data is not correct, otherwise true
   * @public
   */
  validate(savedData) {
    if (!savedData.text) {
      return false;
    }

    return true;
  }

  /**
   * Return Block data
   *
   * @param {HTMLElement} toolsContent
   * @returns {titleStyle}
   */
  save() {
    const elemBox = this.nodes.wrapper.firstChild;
    const elemData = this.nodes.wrapper.firstChild.firstChild;
    return Object.assign(this.data, {
      text: elemData.innerHTML,
      class: elemBox.classList[1],
    });
  }

  /**
   * Renders Block content
   *
   * @returns {HTMLDivElement}
   */
  render() {
    const holder = this.make("div", this.CSS.baseClass);

    this.nodes.wrapper = this.make("div", this.CSS.wrapper);
    this.renderElement();

    holder.appendChild(this.nodes.wrapper);

    return holder;
  }

  renderSettings() {
    const wrapper = document.createElement("div");
    wrapper.classList.add("p-1");
    this.settings.forEach((tune) => {
      const el = document.createElement("select");
      el.classList.add(this.CSS.settingsButton, "form-control", "text-left");
      for (let i = 0; i < ClasstitleStyle.length; i++) {
        const opt = document.createElement("option");
        opt.value = ClasstitleStyle[i].class;
        opt.text = ClasstitleStyle[i].name;
        el.appendChild(opt);
      }
      // el.innerHTML = tune.icon;
      const className = this.data.class;
      if (className) {
        const index = className.replace("titleStyle", "");
        el.selectedIndex = index * 1 - 1;
      }
      el.addEventListener("change", () => {
        const valueSyle = el.value;
        this._toggleTune(tune.name, valueSyle);
        el.classList.toggle(this.CSS.settingsButtonActive);
        this.data.class = valueSyle;
        this.renderElement();
      });

      wrapper.appendChild(el);
    });

    return wrapper;
  }

  /**
   * Click on the Settings Button
   *
   * @private
   * @param tune
   */
  _toggleTune(tune, valueSyle) {
    this.data[tune] = !this.data[tune];
    const elemBox = this.nodes.wrapper.firstChild;

    elemBox.classList.remove(this.CSS.boxDefault);
    elemBox.classList.remove(elemBox.classList[1]);
    elemBox.classList.add(valueSyle);
    this._acceptTuneView();
  }

  /**
   * Add specified class corresponds with activated tunes
   *
   * @private
   */
  _acceptTuneView() {
    this.settings.forEach((tune) => {
      this.nodes.wrapper.classList.toggle(
        this.CSS.wrapper +
          "--" +
          tune.name.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`),
        !!this.data[tune.name]
      );

      if (tune.name === "stretched") {
        this.api.blocks.stretchBlock(this.blockIndex, !!this.data.stretched);
      }
    });
  }

  /**
   * Prepares button for file uploading
   */
  renderElement() {
    const elmIsRender = this.nodes.wrapper.firstChild;
    if (elmIsRender !== null) {
      this.nodes.wrapper.innerHTML = "";
    }
    let data = this.data.text;
    let classBox = this.data.class;
    data = data != undefined ? data : "";
    let classRender = classBox != undefined ? classBox : this.CSS.boxDefault;
    this.nodes.box = this.make("div", ["titleStyle", classRender]);
    this.nodes.box.innerHTML =
      `<h1 class="dataInput" placeholder="Enterキーを押してください" style="">` +
      data +
      `</h1>`;
    this.nodes.box.firstChild.contentEditable = true;
    let elemData = this.nodes.box.lastChild;
    this.nodes.box.firstChild.addEventListener("keydown", (event) => {
      this.data.text = this.nodes.box.firstChild.textContent;
      if (event.keyCode === 13) {
        event.preventDefault();
        // event.stopPropagation();
      }
    });
    this.nodes.box.addEventListener("keydown", (event) => {
      if (event.keyCode === 13) {
        // event.preventDefault();
        event.stopPropagation();
      }
    });
    elemData.addEventListener("keydown", (event) => {
      if (event.keyCode === 13) event.stopPropagation();
    });
    elemData.contentEditable = true;

    this.nodes.box.addEventListener("paste", (e) => {
      e.stopPropagation();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      // paste = paste.toUpperCase();
      const selection = window.getSelection();
      if (!selection.rangeCount) return false;
      selection.deleteFromDocument();
      paste;
      selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      e.preventDefault();
      e.stopImmediatePropagation();
      window.getSelection().removeAllRanges();
    });
    this.nodes.wrapper.appendChild(this.nodes.box);
  }

  /**
   * Fires after clicks on the Toolbox titleStyleToolCustom Icon
   * Initiates click on the Select File button
   *
   * @public
   */
  // appendCallback() {
  //   this.nodes.box.click();
  // }

  /**
   * Checks if any of Tool's fields have data
   *
   * @returns {boolean}
   */

  pluginHasData() {
    return this.data.text !== "";
  }

  /**
   * Used by Editor paste handling API.
   * Provides configuration to handle P tags.
   *
   * @returns {{tags: string[]}}
   */
  static get pasteConfig() {
    return {
      tags: ["h1"],
    };
  }

  /**
   * On paste callback fired from Editor.
   *
   * @param {PasteEvent} event - event with pasted data
   */
  onPaste(event) {
    switch (event.type) {
      case "tag":
        break;
    }
  }
  /**
   * Removes tool's loader
   */
  removeLoader() {
    setTimeout(
      () =>
        this.nodes.wrapper.classList.remove(
          this.CSS.wrapperLoading,
          this.CSS.loader
        ),
      LOADER_TIMEOUT
    );
  }

  /**
   * If file uploading failed, remove loader and show notification
   *
   * @param {string} errorMessage -  error message
   */
  uploadingFailed(errorMessage) {
    this.api.notifier.show({
      message: errorMessage,
      style: "error",
    });

    this.removeLoader();
  }

  /**
   * Return titleStyle Tool's data
   *
   * @returns {titleStyle}
   */
  get data() {
    return this._data;
  }

  /**
   * Stores all Tool's data
   *
   * @param {titleStyle} data
   */
  set data({ file, title }) {}

  /**
   * Moves caret to the end of contentEditable element
   *
   * @param {HTMLElement} element - contentEditable element
   */
  moveCaretToEnd(element) {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  /**
   * Helper method for elements creation
   *
   * @param tagName
   * @param classNames
   * @param attributes
   * @returns {HTMLElement}
   */
  make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }
}
