/**
 * Build styles
 */
require("./index.css").toString();
const toolboxIcon = `<svg width="14" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M304 32H16A16 16 0 0 0 0 48v96a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-32h56v304H80a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-40V112h56v32a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm256 336h-48V144h48c14.31 0 21.33-17.31 11.31-27.31l-80-80a16 16 0 0 0-22.62 0l-80 80C379.36 126 384.36 144 400 144h48v224h-48c-14.31 0-21.32 17.31-11.31 27.31l80 80a16 16 0 0 0 22.62 0l80-80C580.64 386 575.64 368 560 368z"/></svg>`;
/**
 * LineHeight Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
class LineHeight {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS() {
    return "cdx-lineHeighter";
  }

  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor({ api }) {
    this.api = api;

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */
    this.button = null;

    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = "MARK";

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    };
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline() {
    return true;
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render() {
    this.parentNode = this.api.ui.nodes.wrapper.parentNode;
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.classList.add(this.iconClasses.base);
    this.button.innerHTML = this.toolboxIcon;
    this.button.addEventListener("click", () => {
      const formSetting = this.parentNode.querySelector(
        ".setting-lineHeighter"
      );
      formSetting.classList.toggle("show");
    });
    return this.button;
  }
  renderActions() {
    this.divSetting = document.createElement("div");
    this.divSetting.classList.add(
      "setting-lineHeighter",
      "bg-white",
      "w-100",
      "p-2"
    );
    this.divSetting.style.zIndex = 3;
    const div = document.createElement("div");
    div.classList.add("p-1");
    const el = document.createElement("select");
    el.classList.add("form-control", "text-left", "selectLineHeight");
    for (let i = 0; i < 100; i++) {
      const opt = document.createElement("option");
      opt.value = i + "px";
      opt.text = i + "px";
      el.appendChild(opt);
    }
    el.value = "20px";

    this.divSetting.appendChild(el);

    const elemToolBar = document.querySelector(".ce-inline-toolbar");
    const formSetting = document.querySelector(".setting-lineHeighter");
    if (formSetting == null) elemToolBar.appendChild(this.divSetting);

    // const elemToolBar = document.querySelector(".ce-inline-toolbar");
    // const formSetting = document.querySelector(".setting-lineHeight");
    // if (formSetting == null) elemToolBar.appendChild(this.divSetting);

    return this.divSetting;
  }
  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    if (!range) {
      return;
    }
    const elemToolBar = document.querySelector(".ce-inline-toolbar");
    const btnApplyLineHeight =
      this.parentNode.querySelector(".selectLineHeight");

    let termWrapper = this.api.selection.findParentTag(
      this.tag,
      LineHeight.CSS
    );
    if (termWrapper) {
      let dataSize = termWrapper.style.lineHeight;
      btnApplyLineHeight.value = dataSize;
    }

    btnApplyLineHeight.addEventListener("change", (event) => {
      if (!range) {
        return;
      }
      const valueLineHeight = event.currentTarget.value;
      // const valuePrecent = elmSelectPrecent.value;
      /**
       * If start or end of selection is in the highlighted block
       */
      if (termWrapper) {
        // this.unwrapHeightLine(termWrapper);
        this.wrapHeightLine(range, valueLineHeight, termWrapper);
      } else {
        this.wrapHeightLine(range, valueLineHeight);
      }
      elemToolBar.classList.remove("ce-inline-toolbar--showed");
      if (window.getSelection) {
        if (window.getSelection().empty) {
          // Chrome
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          // Firefox
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        // IE?
        document.selection.empty();
      }
    });
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrapHeightLine(range, valueLineHeight, termWrapper) {
    /**
     * Create a wrapper for highlighting
     */
    // let elSelect = range.commonAncestorContainer;
    // if (elSelect.children && elSelect.children.length > 0) {
    //   let mark = elSelect.getElementsByTagName("mark");
    //   if (mark.length) {
    //     elSelect.style.lineHeight = valueLineHeight;
    //     mark.forEach((el) => {
    //       el.style.lineHeight = valueLineHeight;
    //     });
    //     return;
    //   }
    // }
    let termWrapperOther = this.api.selection.findParentTag(this.tag);
    if (termWrapperOther) {
      termWrapperOther.style.lineHeight = valueLineHeight;
      termWrapperOther.classList.add(LineHeight.CSS);
    } else if (termWrapper) {
      termWrapper.style.lineHeight = valueLineHeight;
      termWrapper.classList.add(LineHeight.CSS);
    } else {
      let lineHeighter = document.createElement(this.tag);
      lineHeighter.style.lineHeight = valueLineHeight;
      lineHeighter.classList.add(LineHeight.CSS);

      /**
       * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
       * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
       *
       * // range.surroundContents(span);
       */
      lineHeighter.appendChild(range.extractContents());
      range.insertNode(lineHeighter);

      /**
       * Expand (add) selection to highlighted block
       */
      this.api.selection.expandToTag(lineHeighter);
    }
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrapHeightLine(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);
    let sel = window.getSelection();
    let range = sel.getRangeAt(0);

    let unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    // sel.removeAllRanges();
    // sel.addRange(range);
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    const termTag = this.api.selection.findParentTag(this.tag, LineHeight.CSS);

    const el = this.parentNode.querySelector(".selectLineHeight");
    console.log(el, "el");
    if (termTag) {
      el.value = termTag.style.lineHeight;
    }
    this.button.classList.toggle(this.iconClasses.active, !!termTag);
    let toolbar = document.getElementsByClassName(
      "ce-inline-toolbar--left-oriented"
    );
    if (toolbar && toolbar[0]) toolbar[0].style.display = "none";
  }

  /**
   * Get Tool icon's SVG
   * @return {string}
   */
  get toolboxIcon() {
    return toolboxIcon;
  }

  /**
   * Sanitizer rule
   * @return {{lineHeight: {class: string}}}
   */
  static get sanitize() {
    return {
      mark: {
        class: LineHeight.CSS,
        style: true,
      },
    };
  }
}

module.exports = LineHeight;
