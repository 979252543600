/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import css from "./index.css";
import ClassButtonStyle from "./classButtonStyle";

export default class AnyButton {
  /**
   *
import { type } from "os";
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: "ボタン",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512" height="20" viewBox="0 0 512 512" width="20"><path d="m237.102 366v-90.018h-90c-11.046 0-20-8.954-20-20s8.954-20 20-20h90v-90.982c0-11.046 8.954-20 20-20s20 8.954 20 20v90.982h90c11.046 0 20 8.954 20 20s-8.954 20-20 20h-90v90.018c0 11.046-8.954 20-20 20s-20-8.954-20-20zm254.898-15c11.046 0 20-8.954 20-20v-251c0-44.112-35.888-80-80-80h-352c-44.112 0-80 35.888-80 80v352c0 44.112 35.888 80 80 80h352c44.112 0 80-35.888 80-80 0-11.046-8.954-20-20-20s-20 8.954-20 20c0 22.056-17.944 40-40 40h-352c-22.056 0-40-17.944-40-40v-352c0-22.056 17.944-40 40-40h352c22.056 0 40 17.944 40 40v251c0 11.046 8.954 20 20 20z"/></svg>',
    };
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   *
   * @return {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }
  /**
   *
   * @returns {boolean}
   */
  static get enableLineBreaks() {
    return false;
  }

  /**
   *
   * @returns {{EDIT: number, VIEW: number}}
   * @constructor
   */
  static get STATE() {
    return {
      EDIT: 0,
      VIEW: 1,
    };
  }
  /**
   *
   * @param data
   */
  set data(data) {
    this._data = Object.assign(
      {},
      {
        link: this.api.sanitizer.clean(data.link || "", AnyButton.sanitize),
        checkbox: this.api.sanitizer.clean(
          data.checkbox || "",
          AnyButton.sanitize
        ),
        text: this.api.sanitizer.clean(data.text || "", AnyButton.sanitize),
        style: this.api.sanitizer.clean(data.style || "", AnyButton.sanitize),
        type: this.api.sanitizer.clean(data.type || "", AnyButton.sanitize),
        twitter: this.api.sanitizer.clean(
          data.twitter || "",
          AnyButton.sanitize
        ),
        facebook: this.api.sanitizer.clean(
          data.facebook || "",
          AnyButton.sanitize
        ),
        pocket: this.api.sanitizer.clean(data.pocket || "", AnyButton.sanitize),
        feedly: this.api.sanitizer.clean(data.feedly || "", AnyButton.sanitize),
      }
    );
  }
  /**
   *
   * @returns {{text: string, link: string}}
   */
  get data() {
    return this._data;
  }

  /**
   * セーブ時のバリデーション
   * @param savedData
   * @returns {boolean}
   */
  // eslint-disable-next-line no-unused-vars
  validate(savedData) {
    if (
      this._data.type == "listSocialVerti" ||
      this._data.type == "listSocialHoli"
    ) {
      if (
        this._data.twitter === "" &&
        this._data.facebook === "" &&
        this._data.pocket === "" &&
        this._data.feedly === ""
      ) {
        return false;
      }
    } else {
      if (
        this._data.link === "" ||
        (this._data.link === "" && this._data.text === "")
      ) {
        return false;
      }
    }

    return true;
  }
  /**
   *
   * @param block
   * @returns {{caption: string, text: string, alignment: string}}
   */
  // eslint-disable-next-line no-unused-vars
  save(block) {
    return this._data;
  }

  /**
   * タグを全部削除する
   * @returns {{link: boolean, text: boolean}}
   */
  static get sanitize() {
    return {
      text: false,
      link: false,
    };
  }

  /**
   *
   * @param data
   * @param config
   * @param api
   * @param readOnly
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this.nodes = {
      wrapper: null,
      container: null,
      inputHolder: null,
      toggleHolder: null,
      anyButtonHolder: null,
      textInput: null,
      linkInput: null,
      registButton: null,
      anyButton: null,
      checkbox: null,
      checkboxLabel: null,
    };
    //css overwrite
    const _CSS = {
      baseClass: this.api.styles.block,
      hide: "hide",
      btn: "btnStyle",
      container: "anyButtonContainer",
      input: "anyButtonContainer__input",

      inputHolder: "anyButtonContainer__inputHolder",
      inputText: "anyButtonContainer__input--text",
      inputLink: "anyButtonContainer__input--link",
      registButton: "anyButtonContainer__registerButton",
      anyButtonHolder: "anyButtonContainer__anyButtonHolder",
      btnColor: "btn-square",
      toggleSwitch: "toggle-switch",
      toggleInput: "toggle-input",
      toggleLabel: "toggle-label",
      selectStyle: "anyButtonContainer__select",
      inputMulti: "anyButtonContainer__socialList",
      inputLinkTwitter: "anyButtonContainer__socialList_twitter",
      inputLinkFacebook: "anyButtonContainer__socialList_facebook",
      inputLinkPocket: "anyButtonContainer__socialList_pocket",
      linkInputFeedly: "anyButtonContainer__socialList_feedly",
    };

    this.CSS = Object.assign(_CSS, config.css);

    this.data = {
      link: "",
      text: "",
      style: "",
      type: "",
      checkbox: "",
      twitter: "",
      facebook: "",
      pocket: "",
      feedly: "",
    };
    this.data = data;
  }

  render() {
    this.nodes.wrapper = this.make("div", this.CSS.baseClass);
    this.nodes.container = this.make("div", this.CSS.container); //twitter-embed-tool

    //入力用
    this.nodes.inputHolder = this.makeInputHolder();
    //toggle
    this.nodes.toggleHolder = this.makeToggle();
    //display button
    this.nodes.anyButtonHolder = this.makeAnyButtonHolder();
    this.nodes.container.appendChild(this.nodes.toggleHolder);
    this.nodes.container.appendChild(this.nodes.inputHolder);
    this.nodes.container.appendChild(this.nodes.anyButtonHolder);
    if (
      this._data.type == "listSocialVerti" ||
      this._data.type == "listSocialHoli"
    ) {
      if (
        this._data.twitter != "" ||
        this._data.facebook != "" ||
        this._data.pocket != "" ||
        this._data.feedly != ""
      ) {
        this.init();
        this.show(AnyButton.STATE.VIEW);
      }
    } else {
      if (
        this._data.link !== "" ||
        (this._data.link !== "" && this._data.text !== "")
      ) {
        this.init();
        this.show(AnyButton.STATE.VIEW);
      }
    }
    this.nodes.wrapper.appendChild(this.nodes.container);

    return this.nodes.wrapper;
  }

  makeInputHolder() {
    this.nodes.selectStyle = this.make("select", [
      this.api.styles.selectStyle,
      this.CSS.selectStyle,
    ]);
    for (let i = 0; i < ClassButtonStyle.length; i++) {
      const opt = document.createElement("option");
      opt.value = ClassButtonStyle[i].class;
      opt.text = ClassButtonStyle[i].name;
      this.nodes.selectStyle.appendChild(opt);
    }
    const inputHolder = this.make("div", [this.CSS.inputHolder]);
    inputHolder.appendChild(this.nodes.selectStyle);
    this.nodes.textInput = this.make(
      "div",
      [this.api.styles.input, this.CSS.input, this.CSS.inputText],
      {
        contentEditable: !this.readOnly,
      }
    );
    this.nodes.textInput.dataset.placeholder = this.api.i18n.t("Button Text");

    this.nodes.linkInput = this.make(
      "div",
      [this.api.styles.input, this.CSS.input, this.CSS.inputLink],
      {
        contentEditable: !this.readOnly,
      }
    );

    this.nodes.linkInput.dataset.placeholder = this.api.i18n.t("Link Url");

    this.nodes.registButton = this.make("button", [
      this.api.styles.button,
      this.CSS.registButton,
    ]);
    this.nodes.registButton.type = "button";
    this.nodes.registButton.textContent = this.api.i18n.t("Set");
    this.nodes.textInput.addEventListener("keyup", (event) => {
      const valueInputText = this.nodes.selectStyle.value;
      var typeItemBtn = ClassButtonStyle.filter(
        (item) => item.class === valueInputText
      );
      if (valueInputText == "") {
        this.nodes.toggleInput.disabled = true;
      } else {
        this.nodes.toggleInput.disabled = false;
        this.data = {
          link: this.nodes.linkInput.textContent,
          text: this.nodes.textInput.textContent,
          style: this.nodes.selectStyle.value,
          type: typeItemBtn.type,
          twitter: "",
          facebook: "",
          pocket: "",
          feedly: "",
        };
      }
    });
    this.nodes.linkInput.addEventListener("keyup", (event) => {
      const valueInputText = this.nodes.selectStyle.value;
      var typeItemBtn = ClassButtonStyle.filter(
        (item) => item.class === valueInputText
      );
      if (valueInputText == "") {
        this.nodes.toggleInput.disabled = true;
      } else {
        this.nodes.toggleInput.disabled = false;
        this.data = {
          link: this.nodes.linkInput.textContent,
          text: this.nodes.textInput.textContent,
          style: this.nodes.selectStyle.value,
          type: typeItemBtn.type,
          twitter: "",
          facebook: "",
          pocket: "",
          feedly: "",
        };
      }
    });
    if (
      (this._data != undefined && this._data.text != "") ||
      this._data.type != ""
    ) {
      const dataType = this._data.type;
      this.renderElementSetting(dataType, inputHolder);
    }
    let typeButton;
    this.nodes.selectStyle.addEventListener("change", (event) => {
      const valueStyle = event.currentTarget.value;
      var itemClassStyle = ClassButtonStyle.filter(
        (item) => item.class === valueStyle
      );
      typeButton = itemClassStyle[0].type;
      this._data.style = valueStyle;
      this._data.type = typeButton;
      this.nodes.toggleInput.disabled = true;
      this.renderElementSetting(typeButton, inputHolder);
    });
    inputHolder.appendChild(this.nodes.textInput);
    inputHolder.appendChild(this.nodes.linkInput);

    // add checkbox
    const div = document.createElement("div");
    div.className = "checkbox-bt-url";
    this.nodes.checkbox = document.createElement("input");
    this.nodes.checkbox.type = "checkbox";
    this.nodes.checkbox.className = "checkbox-bt";
    if (this._data.checkbox) this.nodes.checkbox.checked = true;
    div.appendChild(this.nodes.checkbox);
    const checkboxLabel = document.createElement("label");
    checkboxLabel.htmlFor = "id";
    checkboxLabel.appendChild(document.createTextNode("新しいタブを開く"));
    div.appendChild(checkboxLabel);
    inputHolder.appendChild(div);
    inputHolder.appendChild(this.nodes.registButton);

    // eslint-disable-next-line no-unused-vars
    this.nodes.registButton.addEventListener("click", (event) => {
      if (
        (this._data != undefined && this._data.text != "") ||
        this._data.type != ""
      ) {
        if (
          this._data.type == "listSocialVerti" ||
          this._data.type == "listSocialHoli"
        ) {
          if (
            this._data.twitter != "" ||
            this._data.facebook != "" ||
            this._data.pocket != "" ||
            this._data.feedly != ""
          ) {
            this.nodes.toggleInput.disabled = false;
            this.show(AnyButton.STATE.VIEW);
          }
        } else {
          if (
            this._data.link !== "" ||
            (this._data.link !== "" && this._data.text !== "")
          ) {
            this._data.checkbox = this.nodes.checkbox.checked;
            this.nodes.toggleInput.disabled = false;
            this.show(AnyButton.STATE.VIEW);
          }
        }
      }
    });
    return inputHolder;
  }
  renderElementSetting(typeButton, inputHolder) {
    if (typeButton === "listSocialHoli" || typeButton === "listSocialVerti") {
      this.nodes.textInput.classList.add("hide");
      this.nodes.linkInput.classList.add("hide");
      this.nodes.textInput.textContent = "";
      this.nodes.linkInput.textContent = "";
      if (!this.nodes.linkInputFacebook === false) return;
      this.nodes.linkInputTwitter = this.make(
        "div",
        [
          this.api.styles.input,
          this.CSS.input,
          this.CSS.inputLink,
          this.CSS.inputMulti,
        ],
        {
          contentEditable: !this.readOnly,
        }
      );
      this.nodes.linkInputFacebook = this.make(
        "div",
        [
          this.api.styles.input,
          this.CSS.input,
          this.CSS.inputLink,
          this.CSS.inputMulti,
        ],
        {
          contentEditable: !this.readOnly,
        }
      );
      this.nodes.linkInputPocket = this.make(
        "div",
        [
          this.api.styles.input,
          this.CSS.input,
          this.CSS.inputLink,
          this.CSS.inputMulti,
        ],
        {
          contentEditable: !this.readOnly,
        }
      );
      this.nodes.linkInputFeedly = this.make(
        "div",
        [
          this.api.styles.input,
          this.CSS.input,
          this.CSS.inputLink,
          this.CSS.inputMulti,
        ],
        {
          contentEditable: !this.readOnly,
        }
      );
      this.nodes.linkInputTwitter.dataset.placeholder =
        this.api.i18n.t("Url Twitter");
      this.nodes.linkInputFacebook.dataset.placeholder =
        this.api.i18n.t("Url Facebook");
      this.nodes.linkInputPocket.dataset.placeholder =
        this.api.i18n.t("Url Pocket");
      this.nodes.linkInputFeedly.dataset.placeholder =
        this.api.i18n.t("Url Feedly");
      inputHolder.insertBefore(
        this.nodes.linkInputFeedly,
        this.nodes.selectStyle.nextSibling
      );
      inputHolder.insertBefore(
        this.nodes.linkInputPocket,
        this.nodes.selectStyle.nextSibling
      );
      inputHolder.insertBefore(
        this.nodes.linkInputFacebook,
        this.nodes.selectStyle.nextSibling
      );
      inputHolder.insertBefore(
        this.nodes.linkInputTwitter,
        this.nodes.selectStyle.nextSibling
      );
      if (
        (this._data != undefined && this._data.text != "") ||
        this._data.type != ""
      ) {
        this.nodes.linkInputTwitter.textContent = this._data.twitter;
        this.nodes.linkInputFacebook.textContent = this._data.facebook;
        this.nodes.linkInputPocket.textContent = this._data.pocket;
        this.nodes.linkInputFeedly.textContent = this._data.feedly;
      }
      let inputLinkSocialList = document.getElementsByClassName(
        this.CSS.inputMulti
      );
      for (var i = 0; i < inputLinkSocialList.length; i++) {
        inputLinkSocialList[i].addEventListener("keyup", (event) => {
          if (
            this.nodes.linkInputTwitter.textContent === "" &&
            this.nodes.linkInputFacebook.textContent === "" &&
            this.nodes.linkInputPocket.textContent === "" &&
            this.nodes.linkInputFeedly.textContent === ""
          ) {
            this.nodes.toggleInput.disabled = true;
          }
          if (
            this.nodes.linkInputTwitter.textContent !== "" ||
            this.nodes.linkInputFacebook.textContent !== "" ||
            this.nodes.linkInputPocket.textContent !== "" ||
            this.nodes.linkInputFeedly.textContent !== ""
          ) {
            let data = {
              link: "",
              text: "",
              style: this.nodes.selectStyle.value,
              type: typeButton,
              twitter: this.nodes.linkInputTwitter.textContent,
              facebook: this.nodes.linkInputFacebook.textContent,
              pocket: this.nodes.linkInputPocket.textContent,
              feedly: this.nodes.linkInputFeedly.textContent,
            };
            this.data = data;
            this.nodes.toggleInput.disabled = false;
          }
        });
      }
    } else {
      if (this.nodes.linkInputTwitter) {
        this.nodes.linkInputTwitter.remove();
        delete this.nodes.linkInputTwitter;
      }
      if (this.nodes.linkInputFacebook) {
        this.nodes.linkInputFacebook.remove();
        delete this.nodes.linkInputFacebook;
      }
      if (this.nodes.linkInputPocket) {
        this.nodes.linkInputPocket.remove();
        delete this.nodes.linkInputPocket;
      }
      if (this.nodes.linkInputFeedly) {
        this.nodes.linkInputFeedly.remove();
        delete this.nodes.linkInputFeedly;
      }

      this.nodes.textInput.classList.remove("hide");
      this.nodes.linkInput.classList.remove("hide");
      let inputLinkSocialList = document.getElementsByClassName(
        this.api.styles.input
      );
      for (var j = 0; j < inputLinkSocialList.length; j++) {
        inputLinkSocialList[j].addEventListener("keyup", (event) => {
          const valueInputText = event.currentTarget.textContent;
          if (valueInputText == "") {
            this.nodes.toggleInput.disabled = true;
          } else {
            this.nodes.toggleInput.disabled = false;
            this.data = {
              link: this.nodes.linkInput.textContent,
              text: this.nodes.textInput.textContent,
              style: this.nodes.selectStyle.value,
              type: typeButton,
              twitter: "",
              facebook: "",
              pocket: "",
              feedly: "",
            };
          }
        });
      }
    }
  }
  init() {
    this.nodes.textInput.textContent = this._data.text;
    this.nodes.linkInput.textContent = this._data.link;
  }

  show(state) {
    this.nodes.anyButton.textContent = this._data.text;
    this.nodes.anyButton.setAttribute("href", this._data.link);
    this.changeState(state);
  }

  makeAnyButtonHolder() {
    const anyButtonHolder = this.make("div", [
      this.CSS.hide,
      this.CSS.anyButtonHolder,
    ]);
    this.nodes.anyButton = this.make("a", [this.CSS.btn, this.CSS.btnColor], {
      // target: "_blank",
      rel: "nofollow noindex noreferrer",
    });
    this.nodes.anyButton.textContent = this.api.i18n.t("Default Button");
    anyButtonHolder.appendChild(this.nodes.anyButton);
    return anyButtonHolder;
  }

  changeState(state) {
    let valueClass = this.nodes.selectStyle.value;
    if (this._data.type !== "" || this._data.text != "") {
      if (
        this._data.type === "listSocialHoli" ||
        this._data.type === "listSocialVerti"
      ) {
        this.renderButtonStyleList(
          this._data.style,
          this._data.type,
          this._data.twitter,
          this._data.facebook,
          this._data.pocket,
          this._data.feedly
        );
      } else {
        this.renderButtonWithStyle(
          this._data.text,
          this._data.link,
          this._data.style,
          this._data.checkbox
        );
      }
    } else {
      if (
        this.nodes.linkInputTwitter != undefined &&
        this.nodes.linkInputFacebook != undefined &&
        this.nodes.linkInputPocket != undefined &&
        this.nodes.linkInputFeedly != undefined &&
        this.nodes.linkInputTwitter.textContent === "" &&
        this.nodes.linkInputFacebook.textContent === "" &&
        this.nodes.linkInputPocket.textContent === "" &&
        this.nodes.linkInputFeedly.textContent === ""
      ) {
        return;
      }
      var itemClassStyle = ClassButtonStyle.filter(
        (item) => item.class === valueClass
      );
      if (
        itemClassStyle[0].type === "listSocialHoli" ||
        itemClassStyle[0].type === "listSocialVerti"
      ) {
        const urlTwitter = this.nodes.linkInputTwitter.textContent;
        const urlFacebook = this.nodes.linkInputFacebook.textContent;
        const urlPocket = this.nodes.linkInputPocket.textContent;
        const urlFeedly = this.nodes.linkInputFeedly.textContent;
        this.renderButtonStyleList(
          itemClassStyle[0].class,
          itemClassStyle[0].type,
          urlTwitter,
          urlFacebook,
          urlPocket,
          urlFeedly
        );
        this.data = {
          link: this.nodes.linkInput.textContent,
          text: this.nodes.textInput.textContent,
          style: this.nodes.selectStyle.value,
          type: itemClassStyle[0].type,
          twitter: this.nodes.linkInputTwitter.textContent,
          facebook: this.nodes.linkInputFacebook.textContent,
          pocket: this.nodes.linkInputPocket.textContent,
          feedly: this.nodes.linkInputFeedly.textContent,
        };
      } else {
        if (
          this.nodes.linkInputTwitter != undefined &&
          this.nodes.linkInputFacebook != undefined &&
          this.nodes.linkInputPocket != undefined &&
          this.nodes.linkInputFeedly != undefined
        ) {
          delete this.nodes.linkInputTwitter;
          delete this.nodes.linkInputFacebook;
          delete this.nodes.linkInputPocket;
          delete this.nodes.linkInputFeedly;
          this.nodes.textInput.classList.remove("hide");
          this.nodes.linkInput.classList.remove("hide");
        }
        if (
          this.nodes.textInput.textContent === "" ||
          this.nodes.linkInput.textContent === ""
        )
          return;
        const textInputBase = this.nodes.textInput.textContent;
        const linkInputBase = this.nodes.linkInput.textContent;
        this.nodes.anyButton.className = "";
        this.nodes.anyButton.classList.add(valueClass);
        this.renderButtonWithStyle(
          textInputBase,
          linkInputBase,
          valueClass,
          this.nodes.checkbox.checked
        );
        this.data = {
          link: this.nodes.linkInput.textContent,
          text: this.nodes.textInput.textContent,
          style: this.nodes.selectStyle.value,
          type: itemClassStyle[0].type,
          twitter: "",
          facebook: "",
          pocket: "",
          feedly: "",
        };
      }
    }
    switch (state) {
      case AnyButton.STATE.EDIT:
        this.nodes.inputHolder.classList.remove(this.CSS.hide);
        this.nodes.anyButtonHolder.classList.add(this.CSS.hide);
        this.nodes.toggleInput.checked = 0;

        break;
      case AnyButton.STATE.VIEW:
        this.nodes.inputHolder.classList.add(this.CSS.hide);
        this.nodes.anyButtonHolder.classList.remove(this.CSS.hide);
        this.nodes.toggleInput.checked = 1;
        this.nodes.toggleInput.disabled = false;
        break;
    }
  }

  renderButtonWithStyle(textInputBase, linkInputBase, valueClass, checked) {
    let elemButton = "";
    if (valueClass === "btn-real") {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fas fa-power-off"></i></a>';
    } else if (valueClass === "btn-real-dent") {
      const icon = '<i class="fas fa-home"></i><span>';
      this.nodes.anyButton.innerHTML = icon;
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fas fa-home"></i></a>';
    } else if (valueClass === "btn-flat-simple") {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fas fa-caret-right"></i><span>' +
        textInputBase +
        `</span></a>`;
      this.nodes.anyButtonHolder.innerHTML = elemButton;
    } else if (valueClass === "btn-flat-logo") {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fas fa-chevron-right"></i><span>' +
        textInputBase +
        `</span></a>`;
    } else if (valueClass === "btn-social-icon-twitter") {
      elemButton =
        `<a class="btn-social-icon-twitter__square mr-2" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fab fa-twitter"></i><span>' +
        textInputBase +
        `</span></a>`;
    } else if (valueClass === "btn-social-icon-facebook") {
      elemButton =
        `<a class="btn-social-icon-facebook__square mr-2" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fab fa-facebook-f"></i></a>';
    } else if (valueClass === "btn-social-icon-instagram") {
      elemButton =
        `<a class="btn-social-icon-instagram__square mr-2" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fab fa-instagram"></i><span>' +
        textInputBase +
        `</span></a>`;
    } else if (valueClass === "btn-social-long-twitter") {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fab fa-twitter"></i><span>' +
        textInputBase +
        `</span></a>`;
    } else if (valueClass === "btn-social-long-facebook") {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fab fa-facebook-f"></i><span>' +
        textInputBase +
        `</span></a>`;
    } else if (valueClass === "btn-social-long-insta") {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fab fa-instagram"></i><span>' +
        textInputBase +
        `</span></a>`;
    } else if (valueClass === "btn-twitter-follow") {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fas fa-user-plus"></i><span>' +
        textInputBase +
        `</span></a>`;
    } else if (valueClass === "insta_btn3") {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        '<i class="fab fa-instagram"></i><span>' +
        textInputBase +
        `</span></a>`;
    } else {
      elemButton =
        `<a class="` +
        valueClass +
        `" rel="nofollow noindex noreferrer" href="` +
        linkInputBase +
        `">` +
        textInputBase +
        `</a>`;
    }
    if (checked) {
      let list = elemButton.split("<a");
      elemButton = `<a ` + list[0] + `target="_blank" ` + list[1];
    }
    this.nodes.anyButtonHolder.innerHTML = elemButton;
  }

  renderButtonStyleList(
    itemClassStyle,
    itemTypeStyle,
    urlTwitter,
    urlFacebook,
    urlPocket,
    urlFeedly
  ) {
    this.nodes.anyButtonHolder.innerHTML = "";
    if (
      itemTypeStyle === "listSocialHoli" ||
      itemTypeStyle === "listSocialVerti"
    ) {
      if (
        itemClassStyle === "roundButton" ||
        itemClassStyle === "socialGlossySmooth" ||
        itemClassStyle === "socialSquare"
      ) {
        let classElement =
          itemClassStyle === "roundButton"
            ? "btn-social-circle"
            : itemClassStyle === "socialGlossySmooth"
            ? "btn-social-circle-glossy"
            : "btn-social-square";
        if (urlTwitter != "") {
          const elemTw =
            `<a href="` +
            urlTwitter +
            `" class="` +
            classElement +
            ` ` +
            classElement +
            `--twitter"><i class="fab fa-twitter"></i></a>`;
          this.nodes.anyButtonHolder.insertAdjacentHTML("beforeend", elemTw);
        }
        if (urlFacebook != "") {
          const elemFb =
            `<a href="` +
            urlFacebook +
            `" class="` +
            classElement +
            ` ` +
            classElement +
            `--facebook"><i class="fab fa-facebook-f"></i></a>`;
          this.nodes.anyButtonHolder.insertAdjacentHTML("beforeend", elemFb);
        }
        if (urlPocket != "") {
          const elemPocket =
            `<a href="` +
            urlPocket +
            `" class="` +
            classElement +
            ` ` +
            classElement +
            `--pocket"><i class="fab fa-get-pocket"></i></a>`;
          this.nodes.anyButtonHolder.insertAdjacentHTML(
            "beforeend",
            elemPocket
          );
        }
        if (urlFeedly != "") {
          const elemFeedly =
            `<a href="` +
            urlFeedly +
            `" class="` +
            classElement +
            ` ` +
            classElement +
            `--feedly"><i class="fas fa-rss"></i></a>`;
          this.nodes.anyButtonHolder.insertAdjacentHTML(
            "beforeend",
            elemFeedly
          );
        }
      } else if (
        itemClassStyle === "socialFlat" ||
        itemClassStyle === "socialIsometric"
      ) {
        let classElement =
          itemClassStyle === "socialFlat"
            ? "btn-social-flat"
            : "btn-social-isometric";
        if (urlTwitter != "") {
          const elemTw =
            `<div><a href="` +
            urlTwitter +
            `" class="` +
            classElement +
            `">
            <span class="` +
            classElement +
            `-icon ` +
            classElement +
            `-icon--twitter">
              <i class="fab fa-twitter"></i>
            </span>
            <span class="` +
            classElement +
            `-text">TWEET</span>
            </a></div>`;
          this.nodes.anyButtonHolder.insertAdjacentHTML("beforeend", elemTw);
        }
        if (urlFacebook != "") {
          const elemFb =
            `<div><a href="` +
            urlFacebook +
            `" class="` +
            classElement +
            `">
            <span class="` +
            classElement +
            `-icon ` +
            classElement +
            `-icon--facebook">
              <i class="fab fa-facebook-f"></i>
            </span>
            <span class="` +
            classElement +
            `-text">SHARE</span>
            </a></div>`;
          this.nodes.anyButtonHolder.insertAdjacentHTML("beforeend", elemFb);
        }
        if (urlPocket != "") {
          const elemPocket =
            `<div><a href="` +
            urlPocket +
            `" class="` +
            classElement +
            `">
            <span class="` +
            classElement +
            `-icon ` +
            classElement +
            `-icon--pocket">
              <i class="fab fa-get-pocket"></i>
            </span>
            <span class="` +
            classElement +
            `-text">POCKET</span>
            </a></div>`;
          this.nodes.anyButtonHolder.insertAdjacentHTML(
            "beforeend",
            elemPocket
          );
        }
        if (urlFeedly != "") {
          const elemFeedly =
            `<div><a href="` +
            urlFeedly +
            `" class="` +
            classElement +
            `">
            <span class="` +
            classElement +
            `-icon ` +
            classElement +
            `-icon--feedly">
              <i class="fas fa-rss"></i>
            </span>
            <span class="` +
            classElement +
            `-text">FEEDLY</span>
            </a></div>`;
          this.nodes.anyButtonHolder.insertAdjacentHTML(
            "beforeend",
            elemFeedly
          );
        }
      } else if (
        itemClassStyle === "socialGiza" ||
        itemClassStyle === "socialSmartPhone"
      ) {
        let classElement =
          itemClassStyle === "socialGiza"
            ? "btn-social-giza"
            : "btn-social-phone";
        let iconStyle =
          itemClassStyle === "socialGiza"
            ? "fas fa-certificate"
            : "fas fa-tablet-alt";
        let iconSmartPhone =
          itemClassStyle === "socialSmartPhone" ? itemClassStyle : "";

        const boxSocial = `<div class="` + classElement + `"></div>`;
        this.nodes.anyButtonHolder.insertAdjacentHTML("beforeend", boxSocial);
        const elebox = this.nodes.anyButtonHolder.firstChild;

        if (urlTwitter != "") {
          const elemTw =
            `<a href="` +
            urlTwitter +
            `" class="` +
            classElement +
            `-twitter">
            <span class="fa-stack">
            <i class="` +
            iconStyle +
            ` fa-stack-2x"></i> 
            <i class="fab fa-twitter fa-stack-1x ` +
            iconSmartPhone +
            `"></i>
            </span></a>`;
          elebox.insertAdjacentHTML("beforeend", elemTw);
        }
        if (urlFacebook != "") {
          const elemFb =
            `<a href="` +
            urlFacebook +
            `" class="` +
            classElement +
            `-facebook">
            <span class="fa-stack">
            <i class="` +
            iconStyle +
            ` fa-stack-2x"></i> 
            <i class="fab fa-facebook-f fa-stack-1x ` +
            iconSmartPhone +
            `"></i>
            </span></a>`;
          elebox.insertAdjacentHTML("beforeend", elemFb);
        }
        if (urlPocket != "") {
          const elemPocket =
            `<a href="` +
            urlPocket +
            `" class="` +
            classElement +
            `-pocket">
            <span class="fa-stack">
            <i class="` +
            iconStyle +
            ` fa-stack-2x"></i> 
            <i class="fab fa-get-pocket fa-stack-1x ` +
            iconSmartPhone +
            `"></i>
            </span></a>`;
          elebox.insertAdjacentHTML("beforeend", elemPocket);
        }
        if (urlFeedly != "") {
          const elemFeedly =
            `<a href="` +
            urlTwitter +
            `" class="` +
            classElement +
            `-feedly">
            <span class="fa-stack">
            <i class="` +
            iconStyle +
            ` fa-stack-2x"></i> 
            <i class="fas fa-rss fa-stack-1x ` +
            iconSmartPhone +
            `"></i>
            </span></a>`;
          elebox.insertAdjacentHTML("beforeend", elemFeedly);
        }
      }
    }
  }

  makeToggle() {
    /**
         * <div class="toggle-switch">
         <input id="toggle" class="toggle-input" type='checkbox' />
         <label for="toggle" class="toggle-label"/>
         </div>
         */
    const toggleHolder = this.make("div", [this.CSS.toggleSwitch]);
    this.nodes.toggleInput = this.make("input", [this.CSS.toggleInput], {
      type: "checkbox",
      id: "toggle",
      disabled: true,
    });
    const label = this.make("label", [this.CSS.toggleLabel], { for: "toggle" });
    this.nodes.toggleInput.addEventListener("change", (event) => {
      var itemClassStyle = this._data;
      if (
        (this._data != undefined && this._data.text != "") ||
        this._data.type != ""
      ) {
        if (
          this._data.type == "listSocialVerti" ||
          this._data.type == "listSocialHoli"
        ) {
          if (
            this._data.twitter != "" ||
            this._data.facebook != "" ||
            this._data.pocket != "" ||
            this._data.feedly != ""
          ) {
            this.data = {
              link: itemClassStyle.link,
              text: itemClassStyle.text,
              style: itemClassStyle.style,
              type: itemClassStyle.type,
              twitter: itemClassStyle.twitter,
              facebook: itemClassStyle.facebook,
              pocket: itemClassStyle.pocket,
              feedly: itemClassStyle.feedly,
            };
            this.nodes.selectStyle.value = itemClassStyle.style;
            this.show(Number(this.nodes.toggleInput.checked));
          } else {
            return;
          }
        } else {
          if (
            this._data.link !== "" ||
            (this._data.link !== "" && this._data.text !== "")
          ) {
            this.data = {
              link: itemClassStyle.link,
              text: itemClassStyle.text,
              style: itemClassStyle.style,
              type: itemClassStyle.type,
              twitter: itemClassStyle.twitter,
              facebook: itemClassStyle.facebook,
              pocket: itemClassStyle.pocket,
              feedly: itemClassStyle.feedly,
            };
            this.nodes.selectStyle.value = itemClassStyle.style;
            this.show(Number(this.nodes.toggleInput.checked));
          } else {
            return;
          }
        }
      } else {
        this.nodes.toggleInput.checked = 0;
      }
    });
    toggleHolder.appendChild(this.nodes.toggleInput);
    toggleHolder.appendChild(label);

    return toggleHolder;
  }

  /**
   * node 作成用
   * @param tagName
   * @param classNames
   * @param attributes
   * @returns {*}
   */
  make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }
}
