/**
 * Build styles
 */
require("./index.css").toString();
const toolboxIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="1 2 13 12" width="13" height="12"><path d="M8.367 9.633L10.7 10.98l-.624 1.135-.787-.025-.78 1.35H6.94l1.193-2.066-.407-.62.642-1.121zm.436-.763l2.899-5.061a1.278 1.278 0 011.746-.472c.617.355.835 1.138.492 1.76l-2.815 5.114-2.322-1.34zM2.62 11.644H5.39a.899.899 0 110 1.798H2.619a.899.899 0 010-1.798z"/></svg>`;
/**
 * Marker Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
class Marker {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS() {
    return "cdx-marker";
  }

  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor({ api }) {
    this.api = api;

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */
    this.button = null;

    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = "MARK";

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    };
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline() {
    return true;
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render() {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.classList.add(this.iconClasses.base);
    this.button.innerHTML = this.toolboxIcon;
    this.button.addEventListener("click", () => {
      const parentNode = this.api.ui.nodes.wrapper.parentNode;
      const formSetting = parentNode.querySelector(".setting-marker");
      console.log("formSetting", formSetting);
      formSetting.classList.toggle("show");
    });
    return this.button;
  }
  renderActions() {
    this.divSetting = document.createElement("div");
    this.divSetting.classList.add("setting-marker", "bg-white", "w-100", "p-2");
    this.divSetting.style.zIndex = 3;
    this.divBoxColor = document.createElement("div");
    var innerHTML = `
        <div class="w-100 mb-2">
          <span class="mb-2">マーカー下線の色</span>
          <input class="form-control colorUnderline" type="color" value="#ff0000">
        </div>
        <div class="w-100 mb-2">
          <span class="mb-2">線の太さ</span>
          <select class="form-control precentTransparent">
            <option value="0">大</option>
            <option value="50%">中</option>
            <option value="90%">小</option>
          </select>
        </div>
        <div class="w-100 text-right">
          <button class="btn btn-sm btn-info applyMarker" type="button">
            <svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="check lg" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-check-lg mx-auto b-icon bi"><g data-v-41be6633=""><path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"></path></g></svg>
            <span class="ml-1">OK</span>
          </button>
          <button class="btn btn-sm btn-secondary clearMarker" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="width: 14px; height: 14px"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
            <span class="ml-1">Clear</span>
          </button>
        </div>`;
    this.divSetting.innerHTML += innerHTML;
    const elemToolBar = document.querySelector(".ce-inline-toolbar");
    const formSetting = document.querySelector(".setting-marker");
    if (formSetting == null) elemToolBar.appendChild(this.divSetting);
    return this.divSetting;
  }
  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    const elemToolBar = document.querySelector(".ce-inline-toolbar");
    const parentNode = this.api.ui.nodes.wrapper.parentNode;
    const btnApplyMarker = parentNode.querySelector(".applyMarker");
    const btnClearMarker = parentNode.querySelector(".clearMarker");
    const elmColor = parentNode.querySelector(".colorUnderline");
    const elmSelectPrecent = parentNode.querySelector(".precentTransparent");
    let termWrapper = this.api.selection.findParentTag(this.tag, Marker.CSS);
    if (termWrapper) {
      const dataColor = termWrapper.getAttribute("dataColor");
      elmColor.value = dataColor;
    }

    btnApplyMarker.addEventListener("click", () => {
      console.log("range", range);
      if (!range) {
        return;
      }
      const valueColor = elmColor.value;
      const valuePrecent = elmSelectPrecent.value;
      /**
       * If start or end of selection is in the highlighted block
       */
      this.wrapMarker(range, valueColor, valuePrecent, termWrapper);
      elemToolBar.classList.remove("ce-inline-toolbar--showed");
      if (window.getSelection) {
        if (window.getSelection().empty) {
          // Chrome
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          // Firefox
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        // IE?
        document.selection.empty();
      }
    });

    btnClearMarker.addEventListener("click", () => {
      if (!range) {
        return;
      }
      /**
       * If start or end of selection is in the highlighted block
       */
      if (!termWrapper) {
        elemToolBar.classList.remove("ce-inline-toolbar--showed");
        return;
      }
      this.unwrapMarker(termWrapper);
      elemToolBar.classList.remove("ce-inline-toolbar--showed");
      if (window.getSelection) {
        if (window.getSelection().empty) {
          // Chrome
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          // Firefox
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        // IE?
        document.selection.empty();
      }
    });
    return;
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrapMarker(range, valueColor, valuePrecent, termWrapper) {
    /**
     * Create a wrapper for highlighting
     */
    let termWrapperOther;
    if (range.commonAncestorContainer.lastElementChild) {
      termWrapperOther = range.commonAncestorContainer.lastElementChild;
    }

    if (termWrapperOther && termWrapperOther.tagName === this.tag) {
      termWrapperOther.style.background =
        "linear-gradient(transparent " +
        valuePrecent +
        ", " +
        valueColor +
        " 0%)";
      termWrapper.setAttribute("dataColor", valueColor);
    } else if (termWrapper) {
      termWrapper.style.background =
        "linear-gradient(transparent " +
        valuePrecent +
        ", " +
        valueColor +
        " 0%)";
      termWrapper.setAttribute("dataColor", valueColor);
    } else {
      let marker = document.createElement(this.tag);
      marker.style.background =
        "linear-gradient(transparent " +
        valuePrecent +
        ", " +
        valueColor +
        " 0%)";
      marker.classList.add(Marker.CSS);
      marker.setAttribute("dataColor", valueColor);

      /**
       * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
       * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
       *
       * // range.surroundContents(span);
       */
      marker.appendChild(range.extractContents());
      range.insertNode(marker);

      /**
       * Expand (add) selection to highlighted block
       */
      this.api.selection.expandToTag(marker);
    }
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrapMarker(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);
    let sel = window.getSelection();
    let range = sel.getRangeAt(0);

    let unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(range);
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    const termTag = this.api.selection.findParentTag(this.tag, Marker.CSS);
    this.button.classList.toggle(this.iconClasses.active, !!termTag);
  }

  /**
   * Get Tool icon's SVG
   * @return {string}
   */
  get toolboxIcon() {
    return toolboxIcon;
  }

  /**
   * Sanitizer rule
   * @return {{mark: {class: string}}}
   */
  static get sanitize() {
    return {
      mark: {
        class: Marker.CSS,
        style: true,
      },
    };
  }
}

module.exports = Marker;
