import ClassBoxStyle from "./classBoxStyle";
// import DownloadIcon from "./svg/arrow-download.svg";
const Icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="width:16px"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M128 32H32C14.31 32 0 46.31 0 64v96c0 17.69 14.31 32 32 32s32-14.31 32-32V96h64c17.69 0 32-14.31 32-32S145.7 32 128 32zM416 32h-96c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32V64C448 46.31 433.7 32 416 32zM128 416H64v-64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96c0 17.69 14.31 32 32 32h96c17.69 0 32-14.31 32-32S145.7 416 128 416zM416 320c-17.69 0-32 14.31-32 32v64h-64c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c17.69 0 32-14.31 32-32v-96C448 334.3 433.7 320 416 320z"/></svg>`;
const LOADER_TIMEOUT = 500;

/**
 * @class BoxStyleToolCustom
 * @classdesc BoxStyleToolCustom for Editor.js 2.0
 *
 * @property {API} api - Editor.js API
 * @property {BoxStyle} data
 * @property {BoxStyleToolCustomConfig} config
 */
export default class BoxStyleToolCustom {
  /**
   * @param {BoxStyle} data
   * @param {object} config
   * @param {API} api
   */
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this.nodes = {
      wrapper: null,
      button: null,
      title: null,
    };

    this._data = data;

    this.blockIndex = this.api.blocks.getCurrentBlockIndex() + 1;
    this.settings = [
      {
        name: "styleBox",
      },
    ];
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   */
  static get toolbox() {
    return {
      icon: Icon,
      title: "囲み枠",
    };
  }

  /**
   * Tool's CSS classes
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      defaultClass: "boxStyle",
      changeClass: this.data.class || "boxStyle1",
      boxDefault: "boxStyle1",
      loader: this.api.styles.loader,
      /**
       * Tool's classes
       */
      wrapper: "cdx-BoxStyle",
      wrapperWithFile: "cdx-BoxStyle--with-file",
      wrapperLoading: "cdx-BoxStyle--loading",
      box: "cdx-BoxStyle__button",
      title: "cdx-BoxStyle__title",
      size: "cdx-BoxStyle__size",
      downloadButton: "cdx-BoxStyle__download-button",
      fileInfo: "cdx-BoxStyle__file-info",
      fileIcon: "cdx-BoxStyle__file-icon",
      settingsButtonActive: "cdx-settings-button--active",
      settingsButton: "cdx-settings-button",
    };
  }

  /**
   * Validate block data:
   * - check for emptiness
   *
   * @param {BoxStyle} savedData — data received after saving
   * @returns {boolean} false if saved data is not correct, otherwise true
   * @public
   */
  validate(savedData) {
    if (!savedData.text) {
      return false;
    }

    return true;
  }

  /**
   * Return Block data
   *
   * @param {HTMLElement} toolsContent
   * @returns {BoxStyle}
   */
  save() {
    const classChange = parseInt(this.CSS.changeClass.slice(8));
    if (classChange < 26) {
      const elemBox = this.nodes.wrapper.firstChild;
      // const elemData = this.nodes.wrapper.firstChild.firstChild;
      // this.data.text = elemData.getInnerHTML().toString();
      // this.data.class = elemBox.classList[1];
      this.data = Object.assign(this.data, {
        text: this.nodes.box.lastElementChild.innerHTML,
        class: elemBox.classList[1],
      });
      this.data.text = this.data.text.replaceAll("<br>", "#%br%$");
      this.data.text = this.data.text.replaceAll("<div>", "#%%$");
      this.data.text = this.data.text.replaceAll("</div>", "#%^%$");
      return this.data;
      // return Object.assign(this.data, {
      //   text: this.nodes.box.lastElementChild.innerHTML,
      //   class: elemBox.classList[1],
      // });
    } else {
      const elemBox = this.nodes.wrapper.firstChild;
      const elemTitle = this.nodes.wrapper.firstChild.firstChild;
      // const elemData = this.nodes.wrapper.firstChild.lastChild;
      this.data.title = elemTitle.textContent;
      this.data.text = this.nodes.wrapper.firstChild.lastChild.innerHTML;
      this.data.text = this.data.text.replaceAll("<br>", "#%br%$");
      this.data.text = this.data.text.replaceAll("<div>", "#%%$");
      this.data.text = this.data.text.replaceAll("</div>", "#%^%$");
      this.data.class = elemBox.classList[1];
    }
    return this.data;
  }

  /**
   * Renders Block content
   *
   * @returns {HTMLDivElement}
   */
  render() {
    const holder = this.make("div", this.CSS.baseClass);

    this.nodes.wrapper = this.make("div", this.CSS.wrapper);
    this.renderElement();

    holder.appendChild(this.nodes.wrapper);

    return holder;
  }

  renderSettings() {
    const wrapper = document.createElement("div");
    wrapper.classList.add("p-1");
    this.settings.forEach((tune) => {
      const el = document.createElement("select");
      el.classList.add(this.CSS.settingsButton, "form-control", "text-left");
      for (let i = 0; i < ClassBoxStyle.length; i++) {
        const opt = document.createElement("option");
        opt.value = ClassBoxStyle[i].class;
        opt.text = ClassBoxStyle[i].name;
        el.appendChild(opt);
      }
      // el.innerHTML = tune.icon;

      el.addEventListener("change", () => {
        const valueSyle = el.value;
        const valueSyleNumber = parseInt(valueSyle.slice(8));
        this._toggleTune(tune.name, valueSyle);
        el.classList.toggle(this.CSS.settingsButtonActive);
        this.data.class = valueSyle;
        if (valueSyleNumber < 26) this.data.title = "";
        this.renderElement();
      });

      wrapper.appendChild(el);
    });

    return wrapper;
  }

  /**
   * Click on the Settings Button
   *
   * @private
   * @param tune
   */
  _toggleTune(tune, valueSyle) {
    this.data[tune] = !this.data[tune];
    const elemBox = this.nodes.wrapper.firstChild;

    elemBox.classList.remove(this.CSS.boxDefault);
    elemBox.classList.remove(elemBox.classList[1]);
    elemBox.classList.add(valueSyle);
    this._acceptTuneView();
  }

  /**
   * Add specified class corresponds with activated tunes
   *
   * @private
   */
  _acceptTuneView() {
    this.settings.forEach((tune) => {
      this.nodes.wrapper.classList.toggle(
        this.CSS.wrapper +
          "--" +
          tune.name.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`),
        !!this.data[tune.name]
      );

      if (tune.name === "stretched") {
        this.api.blocks.stretchBlock(this.blockIndex, !!this.data.stretched);
      }
    });
  }

  /**
   * Prepares button for file uploading
   */
  renderElement() {
    const elmIsRender = this.nodes.wrapper.firstChild;
    if (elmIsRender !== null) {
      this.nodes.wrapper.innerHTML = "";
    }
    let data = this.data.text;
    if (data) {
      data = this.data.text.replaceAll("#%br%$", "<br>");
      data = data.replaceAll("#%%$", "<div>");
      data = data.replaceAll("#%^%$", "</div>");
    }
    let title = this.data.title;
    const classChange = parseInt(this.CSS.changeClass.slice(8));
    let classBox = this.data.class;
    data = data != undefined ? data : "";
    title = title != undefined ? title : "タイトル";
    let classRender = classBox != undefined ? classBox : this.CSS.boxDefault;
    this.nodes.box = this.make("div", ["boxStyle", classRender]);
    if (classChange < 26) {
      this.nodes.box.innerHTML =
        `<div class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput" placeholder="Enterキーを押してください" style="">` +
        data +
        `</div>`;
      this.nodes.box.firstChild.addEventListener("keydown", (event) => {
        this.data.text = this.nodes.box.firstChild.innerHTML;
        if (!event.shiftKey && event.keyCode === 13) {
          event.preventDefault();
          event.stopPropagation();
        }
      });
    } else {
      this.nodes.box.innerHTML =
        `<span class="box-title px-2 border-0">` +
        title +
        `</span>
        <div class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput" placeholder="Enterキーを押してください" style="">` +
        data +
        `</div>`;
      this.nodes.box.firstChild.contentEditable = true;
      this.nodes.box.firstChild.addEventListener("keydown", (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          // event.stopPropagation();
        }
      });
      if (this.nodes.box.firstChild.firstChild) {
        this.nodes.box.firstChild.firstChild.addEventListener(
          "keydown",
          (event) => {
            if (event.keyCode === 13) {
              event.preventDefault();
              // event.stopPropagation();
            }
          }
        );
      }
      this.nodes.box.addEventListener("keydown", (event) => {
        if (event.keyCode === 13) {
          // event.preventDefault();
          event.stopPropagation();
        }
        this.data.title = this.nodes.box.firstChild.textContent;
        this.data.text = this.nodes.box.lastChild.innerHTML;
      });
    }

    let elmTextarea = this.nodes.box.lastChild;
    elmTextarea.addEventListener("keydown", (event) => {
      if (event.keyCode === 13) event.stopPropagation();
    });
    elmTextarea.contentEditable = true;
    this.nodes.box.addEventListener("paste", (e) => {
      e.stopPropagation();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      // paste = paste.toUpperCase();
      const selection = window.getSelection();
      if (!selection.rangeCount) return false;
      selection.deleteFromDocument();
      paste;
      selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      e.preventDefault();
      e.stopImmediatePropagation();
      window.getSelection().removeAllRanges();
    });
    this.nodes.wrapper.appendChild(this.nodes.box);
  }

  /**
   * Fires after clicks on the Toolbox BoxStyleToolCustom Icon
   * Initiates click on the Select File button
   *
   * @public
   */
  // appendCallback() {
  //   this.nodes.box.click();
  // }

  /**
   * Checks if any of Tool's fields have data
   *
   * @returns {boolean}
   */
  pluginHasData() {
    return this.data.text !== "";
  }

  /**
   * Removes tool's loader
   */
  removeLoader() {
    setTimeout(
      () =>
        this.nodes.wrapper.classList.remove(
          this.CSS.wrapperLoading,
          this.CSS.loader
        ),
      LOADER_TIMEOUT
    );
  }

  /**
   * If file uploading failed, remove loader and show notification
   *
   * @param {string} errorMessage -  error message
   */
  uploadingFailed(errorMessage) {
    this.api.notifier.show({
      message: errorMessage,
      style: "error",
    });

    this.removeLoader();
  }

  /**
   * Return BoxStyle Tool's data
   *
   * @returns {BoxStyle}
   */
  get data() {
    return this._data;
  }

  /**
   * Stores all Tool's data
   *
   * @param {BoxStyle} data
   */
  set data({ file, title }) {}

  /**
   * Moves caret to the end of contentEditable element
   *
   * @param {HTMLElement} element - contentEditable element
   */
  moveCaretToEnd(element) {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  /**
   * Helper method for elements creation
   *
   * @param tagName
   * @param classNames
   * @param attributes
   * @returns {HTMLElement}
   */
  make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }
}
