import "./index.scss";

import Uploader from "./uploader";
import FileIcon from "./svg/standard.svg";
import CustomFileIcon from "./svg/custom.svg";
// import DownloadIcon from "./svg/arrow-download.svg";
const Icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="height: 16px;"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M88 304H80V256H88C101.3 256 112 266.7 112 280C112 293.3 101.3 304 88 304zM192 256H200C208.8 256 216 263.2 216 272V336C216 344.8 208.8 352 200 352H192V256zM224 0V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64C0 28.65 28.65 0 64 0H224zM64 224C55.16 224 48 231.2 48 240V368C48 376.8 55.16 384 64 384C72.84 384 80 376.8 80 368V336H88C118.9 336 144 310.9 144 280C144 249.1 118.9 224 88 224H64zM160 368C160 376.8 167.2 384 176 384H200C226.5 384 248 362.5 248 336V272C248 245.5 226.5 224 200 224H176C167.2 224 160 231.2 160 240V368zM288 224C279.2 224 272 231.2 272 240V368C272 376.8 279.2 384 288 384C296.8 384 304 376.8 304 368V320H336C344.8 320 352 312.8 352 304C352 295.2 344.8 288 336 288H304V256H336C344.8 256 352 248.8 352 240C352 231.2 344.8 224 336 224H288zM256 0L384 128H256V0z"/></svg>`;
const LOADER_TIMEOUT = 500;

/**
 * @typedef {object} AttachesToolData
 * @description Attaches Tool's output data format
 * @property {AttachesFileData} file - object containing information about the file
 * @property {string} title - file's title
 */

/**
 * @typedef {object} AttachesFileData
 * @description Attaches Tool's file format
 * @property {string} [url] - file's upload url
 * @property {string} [size] - file's size
 * @property {string} [extension] - file's extension
 * @property {string} [name] - file's name
 */

/**
 * @typedef {object} FileData
 * @description Attaches Tool's response from backend
 * @property {string} url - file's url
 * @property {string} name - file's name with extension
 * @property {string} extension - file's extension
 * @property {string} size - file's size
 */

/**
 * @typedef {object} UploadResponseFormat
 * @description This format expected from backend on file upload
 * @property {number} success  - 1 for successful uploading, 0 for failure
 * @property {FileData} file - backend response with uploaded file data.
 */

/**
 * @typedef {object} AttachesToolConfig
 * @description Config supported by Tool
 * @property {string} endpoint - file upload url
 * @property {string} field - field name for uploaded file
 * @property {string} types - available mime-types
 * @property {string} placeholder
 * @property {string} errorMessage
 * @property {object} [uploader] - optional custom uploader
 * @property {function(File): Promise.<UploadResponseFormat>} [uploader.uploadByFile] - custom method that upload file and returns response
 */

/**
 * @class AttachesTool
 * @classdesc AttachesTool for Editor.js 2.0
 *
 * @property {API} api - Editor.js API
 * @property {AttachesToolData} data
 * @property {AttachesToolConfig} config
 */
export default class AttachesPDFTool {
  /**
   * @param {AttachesToolData} data
   * @param {object} config
   * @param {API} api
   */
  constructor({ data, config, api }) {
    this.api = api;

    this.nodes = {
      wrapper: null,
      button: null,
      title: null,
    };

    this._data = {
      file: {},
      title: "",
    };

    this.config = {
      endpoint: config.endpoint || "",
      field: config.field || "file",
      types: config.types || "application/pdf",
      buttonText: config.buttonText || "アップロードファイルを選択してください",
      errorMessage:
        config.errorMessage || "ファイルのアップロードに失敗しました",
      uploader: config.uploader || undefined,
      additionalRequestHeaders: config.additionalRequestHeaders || {},
    };

    this.data = data;

    /**
     * Module for files uploading
     */
    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error),
    });

    this.enableFileUpload = this.enableFileUpload.bind(this);
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   */
  static get toolbox() {
    return {
      icon: Icon,
      title: "アップロードPDF",
    };
  }

  /**
   * Tool's CSS classes
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      apiButton: this.api.styles.button,
      loader: this.api.styles.loader,
      /**
       * Tool's classes
       */
      wrapper: "cdx-attaches",
      wrapperWithFile: "cdx-attaches--with-file",
      wrapperLoading: "cdx-attaches--loading",
      button: "cdx-attaches__button",
      title: "cdx-attaches__title",
      size: "cdx-attaches__size",
      downloadButton: "cdx-attaches__download-button",
      fileInfo: "cdx-attaches__file-info",
      fileIcon: "cdx-attaches__file-icon",
    };
  }

  /**
   * Possible files' extension colors
   */
  get EXTENSIONS() {
    return {
      doc: "#3e74da",
      docx: "#3e74da",
      odt: "#3e74da",
      pdf: "#d47373",
      rtf: "#656ecd",
      tex: "#5a5a5b",
      txt: "#5a5a5b",
      pptx: "#e07066",
      ppt: "#e07066",
      mp3: "#eab456",
      mp4: "#f676a6",
      xls: "#3f9e64",
      html: "#2988f0",
      htm: "#2988f0",
      png: "#f676a6",
      jpg: "#f67676",
      jpeg: "#f67676",
      gif: "#f6af76",
      zip: "#4f566f",
      rar: "#4f566f",
      exe: "#e26f6f",
      svg: "#bf5252",
      key: "#e07066",
      sketch: "#df821c",
      ai: "#df821c",
      psd: "#388ae5",
      dmg: "#e26f6f",
      json: "#2988f0",
      csv: "#3f9e64",
    };
  }

  /**
   * Validate block data:
   * - check for emptiness
   *
   * @param {AttachesToolData} savedData — data received after saving
   * @returns {boolean} false if saved data is not correct, otherwise true
   * @public
   */
  validate(savedData) {
    if (!savedData.file.url) {
      return false;
    }

    return true;
  }

  /**
   * Return Block data
   *
   * @param {HTMLElement} toolsContent
   * @returns {AttachesToolData}
   */
  save() {
    /**
     * If file was uploaded
     */
    if (this.pluginHasData()) {
      // const title = toolsContent.querySelector(`.${this.CSS.title}`).innerHTML;

      // Object.assign(this.data, { title });
      Object.assign(this.data);
    }

    return this.data;
  }

  /**
   * Renders Block content
   *
   * @returns {HTMLDivElement}
   */
  render() {
    const holder = this.make("div", this.CSS.baseClass);

    this.nodes.wrapper = this.make("div", this.CSS.wrapper);

    if (this.pluginHasData()) {
      this.showFileData();
    } else {
      this.prepareUploadButton();
    }

    holder.appendChild(this.nodes.wrapper);

    return holder;
  }

  /**
   * Prepares button for file uploading
   */
  prepareUploadButton() {
    this.nodes.button = this.make("div", [this.CSS.apiButton, this.CSS.button]);
    this.nodes.button.innerHTML = `${Icon} ${this.config.buttonText}`;
    this.nodes.button.addEventListener("click", this.enableFileUpload);
    this.nodes.wrapper.appendChild(this.nodes.button);
  }

  /**
   * Fires after clicks on the Toolbox AttachesTool Icon
   * Initiates click on the Select File button
   *
   * @public
   */
  appendCallback() {
    this.nodes.button.click();
  }

  /**
   * Checks if any of Tool's fields have data
   *
   * @returns {boolean}
   */
  pluginHasData() {
    return (
      this.data.title !== "" ||
      Object.values(this.data.file).some((item) => item !== undefined)
    );
  }

  /**
   * Allow to upload files on button click
   */
  enableFileUpload() {
    this.uploader.uploadSelectedFile({
      onPreview: () => {
        this.nodes.wrapper.classList.add(
          this.CSS.wrapperLoading,
          this.CSS.loader
        );
      },
    });
  }

  /**
   * File uploading callback
   *
   * @param {UploadResponseFormat} response
   */
  onUpload(response) {
    const body = response;

    if (body.success && body.file) {
      const { url, name, size, title } = body.file;

      this.data = {
        file: {
          url,
          extension: name ? name.split(".").pop() : "",
          name,
          size,
        },
        title,
      };

      this.nodes.button.remove();
      this.showFileData();
      this.removeLoader();
    } else {
      this.uploadingFailed(this.config.errorMessage);
    }
  }

  /**
   * Handles uploaded file's extension and appends corresponding icon
   */
  appendFileIcon() {
    const extension = this.data.file.extension || "";
    const extensionColor = this.EXTENSIONS[extension];

    const fileIcon = this.make("div", this.CSS.fileIcon, {
      innerHTML: extensionColor ? CustomFileIcon : FileIcon,
    });

    if (extensionColor) {
      fileIcon.style.color = extensionColor;
      fileIcon.setAttribute("data-extension", extension);
    }

    this.nodes.wrapper.appendChild(fileIcon);
  }

  /**
   * Removes tool's loader
   */
  removeLoader() {
    setTimeout(
      () =>
        this.nodes.wrapper.classList.remove(
          this.CSS.wrapperLoading,
          this.CSS.loader
        ),
      LOADER_TIMEOUT
    );
  }

  /**
   * If upload is successful, show info about the file
   */
  showFileData() {
    // this.nodes.wrapper.classList.add(this.CSS.wrapperWithFile);

    const {
      file: { url },
    } = this.data;
    const image = this.make("object");
    image.width = "430px";
    // image.style.margin = "0 auto";
    image.style.display = "block";
    image.height = "300px";
    const caption = this.make("iframe");
    caption.width = "100%";
    caption.height = "100%";
    caption.type = "application/pdf#view=FitH";

    image.data = url + "?page=hsn#toolbar=0";
    caption.src = url + "?page=hsn#toolbar=0";
    image.appendChild(caption);
    this.nodes.wrapper.appendChild(image);
  }

  /**
   * If file uploading failed, remove loader and show notification
   *
   * @param {string} errorMessage -  error message
   */
  uploadingFailed(errorMessage) {
    this.api.notifier.show({
      message: errorMessage,
      style: "error",
    });

    this.removeLoader();
  }

  /**
   * Return Attaches Tool's data
   *
   * @returns {AttachesToolData}
   */
  get data() {
    return this._data;
  }

  /**
   * Stores all Tool's data
   *
   * @param {AttachesToolData} data
   */
  set data({ file, title }) {
    this._data = Object.assign(
      {},
      {
        file: {
          url: (file && file.url) || this._data.file.url,
          name: (file && file.name) || this._data.file.name,
          extension: (file && file.extension) || this._data.file.extension,
          size: (file && file.size) || this._data.file.size,
        },
        title: title || this._data.title,
      }
    );
  }

  /**
   * Moves caret to the end of contentEditable element
   *
   * @param {HTMLElement} element - contentEditable element
   */
  moveCaretToEnd(element) {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  /**
   * Helper method for elements creation
   *
   * @param tagName
   * @param classNames
   * @param attributes
   * @returns {HTMLElement}
   */
  make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }
}
