export default [
  {
    name: "背景塗りつぶし",
    class: "boxStyle1",
  },
  {
    name: "角丸",
    class: "boxStyle2",
  },
  {
    name: " 背景塗りつぶし",
    class: "boxStyle3",
  },
  {
    name: "上下にだけ線",
    class: "boxStyle4",
  },
  {
    name: "二重線の枠",
    class: "boxStyle5",
  },
  {
    name: "破線のボックス",
    class: "boxStyle6",
  },
  {
    name: "左右二重線",
    class: "boxStyle7",
  },
  {
    name: "左にだけ太線",
    class: "boxStyle8",
  },
  {
    name: "上にだけ太線",
    class: "boxStyle9",
  },
  {
    name: "影をつけた場合",
    class: "boxStyle10",
  },
  {
    name: "スマートなデザインに",
    class: "boxStyle11",
  },
  {
    name: "分厚いボード風",
    class: "boxStyle12",
  },
  {
    name: "影をつけた場合",
    class: "boxStyle13",
  },
  {
    name: "布風",
    class: "boxStyle14",
  },
  {
    name: "ピンクver",
    class: "boxStyle15",
  },
  {
    name: "ストライプ",
    class: "boxStyle16",
  },
  {
    name: "交差線",
    class: "boxStyle17",
  },
  {
    name: "角に円形",
    class: "boxStyle18",
  },
  {
    name: "カギカッコ",
    class: "boxStyle19",
  },
  {
    name: "点線の大括弧",
    class: "boxStyle20",
  },
  {
    name: "グラデーション",
    class: "boxStyle21",
  },
  {
    name: "紙風",
    class: "boxStyle22",
  },
  {
    name: "考えごと風",
    class: "boxStyle23",
  },
  {
    name: "吹き出し",
    class: "boxStyle24",
  },
  {
    name: "めくれたテープ風",
    class: "boxStyle25",
  },
  {
    name: "枠の途中にタイトル",
    class: "boxStyle26",
  },
  {
    name: "ラベルをつけたようなデザイン",
    class: "boxStyle27",
  },
  {
    name: "ボックス内にラベル",
    class: "boxStyle28",
  },
  {
    name: "タイトルを横いっぱいに広げた場合",
    class: "boxStyle29",
  },
  {
    name: "さらに影をつけた場合",
    class: "boxStyle30",
  },
];
