/**
 * Build styles
 */

/**
 * Inline Code Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
class ToggleTool {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS() {
    return "inline-code2";
  }

  /**
   */
  constructor({ api }) {
    this.api = api;
    this.inlineToolbar = api.inlineToolbar;
    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */

    /**
     * CSS classes
     */
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline() {
    return true;
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render() {
    const buttonTool = document.querySelectorAll(
      ".ce-inline-toolbar__buttons button"
    );
    for (let i = 0; i < buttonTool.length; i++) {
      buttonTool[i].addEventListener("click", (e) => {
        const toolName = e.currentTarget.getAttribute("data-tool");
        const inputLink = document.querySelectorAll(
          ".ce-inline-toolbar__actions .ce-inline-tool-hyperlink-wrapper"
        );
        const lineHeight = document.querySelectorAll(
          ".ce-inline-toolbar__actions .setting-lineHeighter"
        );
        const maker = document.querySelectorAll(
          ".ce-inline-toolbar__actions .setting-marker"
        );

        const listSelectFont = document.querySelectorAll(
          ".ce-inline-toolbar__buttons #fontSizeBtn .selectionList"
        );

        if (
          inputLink[0].classList.contains(
            "ce-inline-tool-hyperlink-wrapper--showed"
          ) &&
          toolName !== "link"
        ) {
          document
            .querySelector(
              ".ce-inline-toolbar__buttons button[data-tool='link']"
            )
            .click();
          if (
            toolName === "Marker" ||
            toolName === "link" ||
            toolName === "LineHeight" ||
            toolName === "fontSize"
          ) {
            document
              .querySelector(
                ".ce-inline-toolbar__buttons button[data-tool='" +
                  toolName +
                  "']"
              )
              .click();
          }
        } else if (
          lineHeight[0].classList.contains("show") &&
          toolName !== "LineHeight"
        ) {
          document
            .querySelector(
              ".ce-inline-toolbar__buttons button[data-tool='LineHeight']"
            )
            .click();
          if (
            toolName === "Marker" ||
            toolName === "link" ||
            toolName === "LineHeight" ||
            toolName === "fontSize"
          ) {
            document
              .querySelector(
                ".ce-inline-toolbar__buttons button[data-tool='" +
                  toolName +
                  "']"
              )
              .click();
          }
        } else if (
          maker[0].classList.contains("show") &&
          toolName !== "Marker"
        ) {
          document
            .querySelector(
              ".ce-inline-toolbar__buttons button[data-tool='Marker']"
            )
            .click();
          if (
            toolName === "Marker" ||
            toolName === "link" ||
            toolName === "LineHeight" ||
            toolName === "fontSize"
          ) {
            document
              .querySelector(
                ".ce-inline-toolbar__buttons button[data-tool='" +
                  toolName +
                  "']"
              )
              .click();
          }
        } else if (listSelectFont.length > 0 && toolName !== "fontSize") {
          document
            .querySelector(
              ".ce-inline-toolbar__buttons button[data-tool='fontSize']"
            )
            .click();
          if (listSelectFont.length) {
            listSelectFont.forEach((item) => {
              item.remove();
            });
          }
          if (
            toolName === "Marker" ||
            toolName === "link" ||
            toolName === "LineHeight" ||
            toolName === "fontSize"
          ) {
            document
              .querySelector(
                ".ce-inline-toolbar__buttons button[data-tool='" +
                  toolName +
                  "']"
              )
              .click();
          }
        }
      });
    }
    return;
  }

  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    if (!range) {
      return;
    }

    let termWrapper = this.api.selection.findParentTag(ToggleTool.CSS);

    /**
     * If start or end of selection is in the highlighted block
     */
    if (termWrapper) {
      this.unwrap(termWrapper);
    } else {
      this.wrap(range);
    }
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrap(range) {
    /**
     * Create a wrapper for highlighting
     */
    let span = document.createElement(this.tag);

    span.classList.add(ToggleTool.CSS);

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    span.appendChild(range.extractContents());
    range.insertNode(span);

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(span);
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    let sel = window.getSelection();
    let range = sel.getRangeAt(0);

    let unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(range);
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {}

  /**
   * Get Tool icon's SVG
   * @return {string}
   */
  get toolboxIcon() {
    // eslint-disable-next-line getter-return
    return;
  }

  /**
   * Sanitizer rule
   * @return {{span: {class: string}}}
   */
  static get sanitize() {
    return true;
  }
}

module.exports = ToggleTool;
