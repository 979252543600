export default [
  {
    name: "下線付き",
    class: "titleStyle1",
  },
  {
    name: "点線＋色を変える",
    class: "titleStyle2",
  },
  {
    name: " 二重線",
    class: "titleStyle3",
  },
  {
    name: "上下に線",
    class: "titleStyle4",
  },
  {
    name: "単純な背景色をつける",
    class: "titleStyle5",
  },
  {
    name: "全体を線で囲む",
    class: "titleStyle6",
  },
  {
    name: "背景色＋下線",
    class: "titleStyle7",
  },
  {
    name: "左線",
    class: "titleStyle8",
  },
  {
    name: "左に線＋塗り",
    class: "titleStyle9",
  },
  {
    name: "立体感のあるバー",
    class: "titleStyle10",
  },
  {
    name: "シンプルなボックスシャドウ",
    class: "titleStyle11",
  },
  {
    name: "タグ風",
    class: "titleStyle12",
  },
  {
    name: "吹き出し",
    class: "titleStyle13",
  },
  {
    name: "ステッチ風",
    class: "titleStyle14",
  },
  {
    name: "ステッチを白に",
    class: "titleStyle15",
  },
  {
    name: "角がぺろっと剥がれるデザイン",
    class: "titleStyle16",
  },
  {
    name: "帯（リボン）風",
    class: "titleStyle17",
  },
  {
    name: "蛍光マーカー風",
    class: "titleStyle18",
  },
  {
    name: "途中で色の変わる線",
    class: "titleStyle19",
  },
  {
    name: "色を変えてみた場合",
    class: "titleStyle20",
  },
  {
    name: "線先に矢印",
    class: "titleStyle21",
  },
  {
    name: "先端が尖ったようなバー",
    class: "titleStyle22",
  },
  {
    name: "両先端を尖らせた場合",
    class: "titleStyle23",
  },
  {
    name: "背景をストライプで塗る",
    class: "titleStyle24",
  },
  {
    name: "左に縦線が入ったバージョン",
    class: "titleStyle25",
  },
  {
    name: "ストライプ＋上下線",
    class: "titleStyle26",
  },
  {
    name: "ストライプの下線",
    class: "titleStyle27",
  },
  {
    name: "ストライプの吹き出し",
    class: "titleStyle28",
  },
  {
    name: "両端に線を伸ばす",
    class: "titleStyle29",
  },
  {
    name: "二重線バージョン",
    class: "titleStyle30",
  },
  {
    name: "下側に小さく線をつける",
    class: "titleStyle31",
  },
  {
    name: "スラッシュで囲む",
    class: "titleStyle32",
  },
  {
    name: "交差線",
    class: "titleStyle33",
  },
  {
    name: "角がくるん",
    class: "titleStyle34",
  },
  {
    name: "角がくるん",
    class: "titleStyle34",
  },
  {
    name: "葉っぱ風",
    class: "titleStyle35",
  },
  {
    name: "細カギカッコ",
    class: "titleStyle36",
  },
  {
    name: "大括弧",
    class: "titleStyle37",
  },
  {
    name: "点線バージョン大カッコ",
    class: "titleStyle38",
  },
  {
    name: "立体的なボックス",
    class: "titleStyle39",
  },
  {
    name: "一文字目だけ装飾を変える",
    class: "titleStyle41",
  },
  {
    name: "消えていく下線",
    class: "titleStyle42",
  },
  {
    name: "段々と色が変わっていくパターン",
    class: "titleStyle43",
  },
  {
    name: "背景をグラデーションで塗る",
    class: "titleStyle44",
  },
  {
    name: "影をつけてみた場合",
    class: "titleStyle45",
  },
  {
    name: "上下のグラデーション",
    class: "titleStyle46",
  },
  {
    name: "文字の反射",
    class: "titleStyle47",
  },
  {
    name: "シンプルなチェックマーク",
    class: "titleStyle48",
  },
  {
    name: "おしゃれなシェブロンマーク",
    class: "titleStyle49",
  },
  {
    name: "HTMLタグ風",
    class: "titleStyle50",
  },
  {
    name: "ふきだしアイコン",
    class: "titleStyle51",
  },
  {
    name: "フラットな見出し",
    class: "titleStyle52",
  },
  {
    name: "コードアイコンのマーカー",
    class: "titleStyle53",
  },
  {
    name: "タブ付き",
    class: "titleStyle54",
  },
  {
    name: "タブ付きその2",
    class: "titleStyle55",
  },
  {
    name: "先頭にアイコンをつける例",
    class: "titleStyle56",
  },
  {
    name: "ひらめき",
    class: "titleStyle57",
  },
  {
    name: "円にアイコンを入れてバーをつける",
    class: "titleStyle58",
  },
  {
    name: "円のまわりに影を付けた場合",
    class: "titleStyle59",
  },
  {
    name: "さらに立体的にしてみた場合",
    class: "titleStyle60",
  },
  {
    name: "点滅するカーソル",
    class: "titleStyle61",
  },
  {
    name: "背景色＋角丸",
    class: "titleStyle62",
  },
  {
    name: "シンプル円マーク-赤",
    class: "titleStyle63",
  },
  {
    name: "シンプル円マーク-黄色",
    class: "titleStyle64",
  },
  {
    name: "シンプル円マーク-青",
    class: "titleStyle65",
  },
  {
    name: "シンプル円マーク-緑",
    class: "titleStyle66",
  },
  {
    name: "考えごと風",
    class: "titleStyle67",
  },
  {
    name: "肉球マーカー",
    class: "titleStyle68",
  },
  {
    name: "背景にカラフルな円",
    class: "titleStyle69",
  },
  {
    name: "ステッチ模様をつけた場合",
    class: "titleStyle70",
  },
  {
    name: "先頭につけた場合",
    class: "titleStyle71",
  },
  {
    name: "文字の背景を塗った場合",
    class: "titleStyle72",
  },
];
