import SelectionUtils from "./SelectionUtils";
import "./Hyperlink.css";

export default class Hyperlink {
  // eslint-disable-next-line no-unused-vars
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.toolbar = api.toolbar;
    this.inlineToolbar = api.inlineToolbar;
    this.tooltip = api.tooltip;
    this.i18n = api.i18n;
    this.config = config;
    this.selection = new SelectionUtils();

    this.commandLink = "createLink";
    this.commandUnlink = "unlink";

    this.CSS = {
      wrapper: "ce-inline-tool-hyperlink-wrapper",
      wrapperShowed: "ce-inline-tool-hyperlink-wrapper--showed",
      button: "ce-inline-tool",
      buttonActive: "ce-inline-tool--active",
      buttonModifier: "ce-inline-tool--link",
      buttonUnlink: "ce-inline-tool--unlink",
      input: "ce-inline-tool-hyperlink--input",
      buttonSave: "ce-inline-tool-hyperlink--button",
      buttonClear: "btn-secondary",
    };

    this.targetAttributes = this.config.availableTargets || [
      "_blank", // Opens the linked document in a new window or tab
      "_self", // Opens the linked document in the same frame as it was clicked (this is default)
      "_parent", // Opens the linked document in the parent frame
      "_top", // Opens the linked document in the full body of the window
    ];

    this.relAttributes = this.config.availableRels || [
      "alternate", //Provides a link to an alternate representation of the document (i.e. print page, translated or mirror)
      "author", //Provides a link to the author of the document
      "bookmark", //Permanent URL used for bookmarking
      "external", //Indicates that the referenced document is not part of the same site as the current document
      "help", //Provides a link to a help document
      "license", //Provides a link to licensing information for the document
      "next", //Provides a link to the next document in the series
      "nofollow", //Links to an unendorsed document, like a paid link. ("nofollow" is used by Google, to specify that the Google search spider should not follow that link)
      "noreferrer", //Requires that the browser should not send an HTTP referer header if the user follows the hyperlink
      "noopener", //Requires that any browsing context created by following the hyperlink must not have an opener browsing context
      "prev", //The previous document in a selection
      "search", //Links to a search tool for the document
      "tag", //A tag (keyword) for the current document
    ];

    this.nodes = {
      button: null,
      wrapper: null,
      input: null,
      checkbox: null,
      checkboxLabel: null,
      buttonSave: null,
    };

    this.inputOpened = false;
  }

  render() {
    this.nodes.button = document.createElement("button");
    this.nodes.button.type = "button";
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
    this.nodes.button.appendChild(this.iconSvg("link", 14, 10));
    // this.nodes.button.appendChild(this.iconSvg("unlink", 15, 11));
    return this.nodes.button;
  }

  renderActions() {
    this.nodes.wrapper = document.createElement("div");
    this.nodes.wrapper.classList.add(this.CSS.wrapper);

    // Input
    this.nodes.input = document.createElement("input");
    this.nodes.input.placeholder = "https://...";
    this.nodes.input.classList.add(this.CSS.input);

    // checkbox
    this.nodes.checkbox = document.createElement("input");
    this.nodes.checkbox.type = "checkbox";
    this.nodes.checkbox.className = "checkboxUrl";

    this.nodes.checkboxLabel = document.createElement("label");
    this.nodes.checkboxLabel.htmlFor = "id";
    this.nodes.checkboxLabel.appendChild(
      document.createTextNode("新しいタブを開く")
    );

    this.nodes.divAction = document.createElement("div");
    this.nodes.divAction.classList.add(
      "w-100",
      "d-flex",
      "justify-content-center"
    );
    // Button
    this.nodes.buttonSave = document.createElement("button");
    this.nodes.buttonSave.type = "button";
    this.nodes.buttonSave.classList.add(
      this.CSS.buttonSave,
      "d-inline-block",
      "mr-2",
      "btn-action"
    );
    this.nodes.buttonSave.id = "btsave";

    this.nodes.buttonClear = document.createElement("button");
    this.nodes.buttonClear.type = "button";
    this.nodes.buttonClear.classList.add(
      this.CSS.buttonClear,
      "d-inline-block",
      "ml-2",
      "btn-action"
    );

    this.nodes.buttonSave.innerHTML = this.i18n.t("保存");
    this.nodes.buttonClear.innerHTML = this.i18n.t("クリア");
    this.nodes.buttonClear.addEventListener("click", (event) => {
      this.clearLink(event);
    });

    // append
    this.nodes.wrapper.appendChild(this.nodes.input);
    this.nodes.wrapper.appendChild(this.nodes.checkbox);
    this.nodes.wrapper.appendChild(this.nodes.checkboxLabel);
    this.nodes.divAction.appendChild(this.nodes.buttonSave);
    this.nodes.divAction.appendChild(this.nodes.buttonClear);
    this.nodes.wrapper.appendChild(this.nodes.divAction);
    // fix bug event click on safari -> event mousedown
    const self = this;
    let btSave = document.getElementById("btsave");
    if (document.getElementById("btsave")) {
      btSave.mousedown = null;
      btSave.addEventListener("mousedown", (event) => {
        self.savePressed(event);
      });
    } else {
      const checBtSave = setInterval(frame, 100);
      // eslint-disable-next-line no-inner-declarations
      function frame() {
        if (btSave) {
          btSave.mousedown = null;
          btSave.addEventListener("mousedown", (event) => {
            self.savePressed(event);
          });
          clearInterval(checBtSave);
        } else {
          btSave = document.getElementById("btsave");
        }
      }
    }
    return this.nodes.wrapper;
  }

  surround(range) {
    if (range) {
      if (!this.inputOpened) {
        // this.selection.setFakeBackground();
        this.selection.save();
      } else {
        this.selection.restore();
        // this.selection.removeFakeBackground();
      }
    }
    this.toggleActions();
  }

  get shortcut() {
    return this.config.shortcut || "CMD+L";
  }

  get title() {
    return "Hyperlink";
  }

  static get isInline() {
    return true;
  }

  static get sanitize() {
    return {
      a: {
        href: true,
        target: true,
        rel: true,
      },
    };
  }

  // eslint-disable-next-line no-unused-vars
  checkState(selection = null) {
    const anchorTag = this.selection.findParentTag("A");
    if (anchorTag) {
      // this.nodes.button.classList.add(this.CSS.buttonUnlink);
      this.nodes.button.classList.add(this.CSS.buttonActive);
      // this.openActions();
      const hrefAttr = anchorTag.getAttribute("href");
      const checkbox = anchorTag.getAttribute("target");
      this.nodes.input.value = hrefAttr ? hrefAttr : "";
      this.nodes.checkbox.checked = checkbox == "_blank" ? true : false;
      this.selection.save();
    } else {
      // this.nodes.button.classList.remove(this.CSS.buttonUnlink);
      // this.nodes.button.classList.remove(this.CSS.buttonActive);
    }
    return !!anchorTag;
  }

  // clear() {
  //   console.log(14);
  //   this.closeActions();
  // }

  toggleActions() {
    if (!this.inputOpened) {
      this.openActions(true);
    } else {
      this.closeActions(false);
    }
  }

  openActions(needFocus = false) {
    let parent = document.getElementsByClassName(
      "ce-inline-toolbar--showed"
    )[0];
    parent.style.opacity = 1;
    parent.style.visibility = "visible";
    parent.style.transform = "translateX(-23px)";
    document.getElementsByClassName("ce-inline-toolbar--showed")[0].id =
      "editor-link";
    this.nodes.wrapper.classList.add(this.CSS.wrapperShowed);
    if (needFocus) {
      this.nodes.input.focus();
    }
    this.inputOpened = true;
  }

  closeActions(clearSavedSelection = true) {
    if (this.selection.isFakeBackgroundEnabled) {
      const currentSelection = new SelectionUtils();
      currentSelection.save();
      this.selection.restore();
      this.selection.removeFakeBackground();
      currentSelection.restore();
    }
    this.nodes.wrapper.classList.remove(this.CSS.wrapperShowed);
    this.nodes.input.value = "";

    if (clearSavedSelection) {
      this.selection.clearSaved();
    }
    this.inputOpened = false;
    let parent = document.getElementsByClassName(
      "ce-inline-toolbar--left-oriented"
    )[0];
    parent.style.removeProperty("opacity");
    parent.style.removeProperty("visibility");
  }

  // eslint-disable-next-line no-unused-vars
  clearLink(event) {
    this.nodes.button.classList.remove(this.CSS.buttonActive);
    if (!this.inputOpened) {
      // this.selection.setFakeBackground();
      this.selection.save();
    } else {
      this.selection.restore();
      // this.selection.removeFakeBackground();
    }
    // const parentAnchor = this.selection.findParentTag("A");
    // if (parentAnchor) {
    // this.selection.expandToTag(parentAnchor);
    this.unlink();
    this.closeActions();
    this.checkState();
    this.toolbar.close();
    // }

    this.selection.collapseToEnd();
    this.inlineToolbar.close();
  }

  savePressed(event) {
    // event.preventDefault();
    // event.stopPropagation();
    event.stopImmediatePropagation();

    let value = this.nodes.input.value || "";
    if (!value.trim()) {
      this.selection.restore();
      this.unlink();
      event.preventDefault();
      this.closeActions();
      return;
    }
    if (
      !!this.config.validate &&
      !!this.config.validate === true &&
      !this.validateURL(value)
    ) {
      this.tooltip.show(this.nodes.input, "URL が無効です。", {
        placement: "top",
      });
      setTimeout(() => {
        this.tooltip.hide();
      }, 1000);
      return;
    }

    value = this.prepareLink(value);

    this.selection.restore();
    // this.selection.removeFakeBackground();

    if (this.nodes.checkbox.checked) {
      this.insertLink(value, "_blank");
    } else {
      this.insertLink(value);
    }
    this.selection.collapseToEnd();
    this.inlineToolbar.close();
    this.closeActions();
  }

  validateURL(str) {
    if (str.substring(0, 7) == "http://" || str.substring(0, 8) == "https://") {
      const newUrl = new URL(str);
      return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    }
    return false;
  }

  prepareLink(link) {
    link = link.trim();
    link = this.addProtocol(link);
    return link;
  }

  addProtocol(link) {
    if (/^(\w+):(\/\/)?/.test(link)) {
      return link;
    }

    const isInternal = /^\/[^/\s]?/.test(link),
      isAnchor = link.substring(0, 1) === "#",
      isProtocolRelative = /^\/\/[^/\s]/.test(link);

    if (!isInternal && !isAnchor && !isProtocolRelative) {
      link = "http://" + link;
    }

    return link;
  }

  insertLink(link, target = "", rel = "") {
    let anchorTag = this.selection.findParentTag("A");
    if (anchorTag) {
      this.selection.expandToTag(anchorTag);
      anchorTag.href = link;
    } else {
      document.execCommand(this.commandLink, false, link);
      anchorTag = this.selection.findParentTag("A");
    }
    if (anchorTag) {
      if (target) {
        anchorTag["target"] = target;
      } else {
        anchorTag.removeAttribute("target");
      }
      if (rel) {
        anchorTag["rel"] = rel;
      } else {
        anchorTag.removeAttribute("rel");
      }
    }
  }

  unlink() {
    document.execCommand(this.commandUnlink);
  }

  iconSvg(name, width = 14, height = 14) {
    const icon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    icon.classList.add("icon", "icon--links");
    icon.setAttribute("width", width + "px");
    icon.setAttribute("height", height + "px");
    icon.innerHTML = `<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#${name}"></use>`;
    return icon;
  }

  addOption(element, text, value = null) {
    let option = document.createElement("option");
    option.text = text;
    option.value = value;
    element.add(option);
  }
}
