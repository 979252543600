export default [
  {
    name: "押し込み式",
    class: "btn-square",
    type: "",
  },
  {
    name: "影付き",
    class: "btn-square-shadow",
    type: "",
  },
  {
    name: " 文字をエンボスに",
    class: "btn-square-emboss",
    type: "",
  },
  {
    name: "浮き出し文字",
    class: "btn-square-raised",
    type: "",
  },
  {
    name: "斜めから見たように",
    class: "btn-square-slant",
    type: "",
  },
  {
    name: "上から見たように",
    class: "btn-square-above-look",
    type: "",
  },
  {
    name: "遊び心のあるボタン",
    class: "btn-square-pop",
    type: "",
  },
  {
    name: "さらにポップに",
    class: "btn-square-so-pop",
    type: "",
  },
  {
    name: "おもちゃ風",
    class: "btn-square-toy",
    type: "",
  },
  {
    name: "控えめなリッチボタン",
    class: "btn-square-little-rich",
    type: "",
  },
  {
    name: "表面に丸みのある角丸ボタン",
    class: "btn-square-soft",
    type: "",
  },
  {
    name: "いっそのこと文字ごと立体に",
    class: "btn-text-3d",
    type: "",
  },
  {
    name: "少し浮き上がったふせん風",
    class: "btn-sticky",
    type: "",
  },
  {
    name: "本物のような質感のボタン",
    class: "btn-real",
    type: "",
  },
  {
    name: "周囲をへこませた場合",
    class: "btn-real-dent",
    type: "",
  },
  {
    name: "シンプル",
    class: "btn-flat-simple",
    type: "",
  },
  {
    name: "シンプルなボーダーで囲う",
    class: "btn-flat-border",
    type: "",
  },
  {
    name: "二重線で囲う",
    class: "btn-flat-double-border",
    type: "",
  },
  {
    name: "破線で囲う",
    class: "btn-flat-dashed-border",
    type: "",
  },
  {
    name: "背景色を塗ってみた場合",
    class: "btn-flat-dashed-filled",
    type: "",
  },
  {
    name: "両端ボーダーばさみ",
    class: "btn-flat-vertical-border",
    type: "",
  },
  {
    name: "丸みのある下線付き",
    class: "btn-border-bottom",
    type: "",
  },
  {
    name: "上下線付き",
    class: "btn-horizontal-border",
    type: "",
  },
  {
    name: "左右バージョン",
    class: "btn-vertical-border",
    type: "",
  },
  {
    name: "片側だけ角丸に",
    class: "btn-right-radius",
    type: "",
  },
  {
    name: "ロゴ風フラット",
    class: "btn-flat-logo",
    type: "",
  },
  {
    name: "塗りつぶし＋下線",
    class: "btn-flat-bottom-border",
    type: "",
  },
  {
    name: "塗りつぶし＋上下線",
    class: "btn-flat-horizontal-border",
    type: "",
  },
  {
    name: "上だけ角丸",
    class: "btn-top-radius",
    type: "",
  },
  {
    name: "ストライプ",
    class: "btn-flat-stripe",
    type: "",
  },
  {
    name: "先進感",
    class: "btn-sf-like",
    type: "",
  },
  {
    name: "ステッチ",
    class: "btn-stitch",
    type: "",
  },
  {
    name: "斜めにグラデーション",
    class: "btn-gradient-simple",
    type: "",
  },
  {
    name: "立体的に",
    class: "btn-gradient-3d",
    type: "",
  },
  {
    name: "文字を白に",
    class: "btn-gradient-3d-simple",
    type: "",
  },
  {
    name: "オレンジにしてみた場合",
    class: "btn-gradient-3d-orange",
    type: "",
  },
  {
    name: "角丸のフラットボタン",
    class: "btn-gradient-radius",
    type: "",
  },
  {
    name: "シンプルな長方形に",
    class: "btn-gradient-flat",
    type: "",
  },
  {
    name: "シンプル",
    class: "btn-circle-flat",
    type: "",
  },
  {
    name: "ステッチ",
    class: "btn-circle-stitch",
    type: "",
  },
  {
    name: "文字と線をひかえめに",
    class: "btn-circle-border",
    type: "",
  },
  {
    name: "下側に影をつけた場合",
    class: "btn-circle-3d",
    type: "",
  },
  {
    name: "文字をエンボスに",
    class: "btn-circle-3d-emboss",
    type: "",
  },
  {
    name: "文字を凹ませる",
    class: "btn-circle-3d-dent",
    type: "",
  },
  {
    name: "シンプルな円形",
    class: "btn-circle-border-simple",
    type: "",
  },
  {
    name: "二重線",
    class: "btn-circle-border-double",
    type: "",
  },
  {
    name: "ミステリアスなボタン",
    class: "btn-circle-fishy",
    type: "",
  },
  {
    name: "カービー風",
    class: "btn-circle-kirby",
    type: "",
  },
  {
    name: "シンプルなアイコン風ボタン - Twitter",
    class: "btn-social-icon-twitter",
    type: "",
  },
  {
    name: "シンプルなアイコン風ボタン - Facebook",
    class: "btn-social-icon-facebook",
    type: "",
  },
  {
    name: "シンプルなアイコン風ボタン - Instagram",
    class: "btn-social-icon-instagram",
    type: "",
  },
  {
    name: "横長のフォローボタン - Twitter",
    class: "btn-social-long-twitter",
    type: "",
  },
  {
    name: "横長のフォローボタン - Facebook",
    class: "btn-social-long-facebook",
    type: "",
  },
  {
    name: "横長のフォローボタン - Instagram",
    class: "btn-social-long-insta",
    type: "",
  },
  {
    name: "丸い立体ボタン",
    class: "roundButton",
    type: "listSocialHoli",
  },
  {
    name: "フラット",
    class: "socialFlat",
    type: "listSocialVerti",
  },
  {
    name: "アイソメトリック",
    class: "socialIsometric",
    type: "listSocialVerti",
  },
  {
    name: "ギザギザ",
    class: "socialGiza",
    type: "listSocialHoli",
  },
  {
    name: "スマホの中にアイコンを表示",
    class: "socialSmartPhone",
    type: "listSocialHoli",
  },
  {
    name: "光沢のある滑らかな円形ボタン",
    class: "socialGlossySmooth",
    type: "listSocialHoli",
  },
  {
    name: "シンプルなフラットボタン",
    class: "socialSquare",
    type: "listSocialHoli",
  },
  {
    name: "旧Twitter風",
    class: "btn-twitter-follow",
    type: "",
  },
  {
    name: "インスタグラム用ボタン",
    class: "insta_btn3",
    type: "",
  },
  {
    name: "斜めにカットしたようなデザイン",
    class: "btn-edge",
    type: "",
  },
  {
    name: "一部にだけ線",
    class: "btn-partial-line",
    type: "",
  },
  {
    name: "交差線",
    class: "btn-cross",
    type: "",
  },
  {
    name: "角括弧",
    class: "btn-brackets",
    type: "",
  },
  {
    name: "下側を切り取られちゃった風",
    class: "btn-bottom-jagged",
    type: "",
  },
];
